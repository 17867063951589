import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ReceivablesLogService extends RestService {
  private _url = 'ReceivablesLog';
  
  constructor(protected _http: HttpClient,
    protected loadingService: LoadingService) {
    super(_http, loadingService);
  }

  async sendBillByMail(rId: number, email: string){
    const data = {
      receivable_log_id: rId,
      email
    }

    const send = await this.postApi(this._url + '/sendBillByEmail', data)

    return send
  }
}