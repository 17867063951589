import { RestService } from "./rest.service";

export class PartnersUsersVouchersService extends RestService {
    private _url = 'PartnersUsersVouchers';

    async getPartnerUsersVouchers(filter_data, contains = '') {
        let qs: string = '';

        if(Array.isArray(filter_data)){
            filter_data.map(item => {
              if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
              }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
              }
            })
          }
        return await this.getApi(this._url, { params: {query: qs, contains } });
    }
}