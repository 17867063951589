import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchesUsersService extends RestService { 
  private _url = 'ResearchesUsers';
  
  constructor(protected _http: HttpClient,
    protected loadingService: LoadingService) {
    super(_http, loadingService);
}

  insertResearcheUser(info: any): any {
    let data = JSON.stringify(info);
    return this.postApi(this._url, data);
  }

  insertMany(info: any) : any {
    let data = {} 
    data['isArray'] = true
    data['items'] = info;
    return this.postApi(this._url, JSON.stringify(data));
  }

  updateResearcheUser(info: any): any {
    let data = JSON.stringify(info);
    return this.putApi(this._url + '/' + info.id, data);
  }

  deleteResearcheUser(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getResearchesUsers(filter_data: any, contain_string: string = '', order_string: string = '+ResearchesUsers.id', page: number = 0, fields: string = '', limit: number = 50): any {
    let qs: string = '';
    let paging: string = '';

    if ( filter_data.length > 0 ) {
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }
    
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getResearcheUser(id){
    return this.getApi(this._url + '/' + id);
  }

}