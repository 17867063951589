import { Component, OnInit } from '@angular/core';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-vote-poll',
  templateUrl: './vote-poll.component.html',
  styleUrls: ['./vote-poll.component.scss']
})
export class VotePollComponent implements OnInit {
  auth                : any;
  cardShare           : any = [];
  show_name           : string = '';
  actual_img          : string = '';
  peoplesSelected    : any[]=[
    {
      id: '1',
      name: 'Juninho Antonio Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null
    },
    {
      id: '2',
      name: 'Alana Silva',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null

    },
    {
      id: '3',
      name: 'Aurora Lamenha',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '4',
      name: 'Bruno Tavarez',
      img_url: null
    },
    {
      id: '5',
      name: 'Camila Mendes',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '6',
      name: 'Camila Oliveira',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '7',
      name: 'Flávia Shnowtz',
      img_url: null
    },
    {
      id: '8',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '9',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '10',
      name: 'Rodrigo Soares',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '11',
      name: 'Pedro Rafael',
      img_url: null
    },
    {
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
  ]
  constructor(
    private authService: AuthMainService,

  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));

    
    this.auth.user.isComition = false; // QUANDO FOR COMISSÂO == TRUE; QUANDO FOR FORMANDO == false.  

		if ( this.auth.user.nickname != null ) {
			this.show_name	= this.auth.user.nickname;
		} else {
			let full_name	= this.auth.user.name.split(" ");
			this.show_name	= full_name[0];
		}
		if ( !localStorage.getItem('avatar') ) {
			this.authService.getAvatar(this.auth.user.id).then(result => {
				this.actual_img   = localStorage.getItem('avatar');
			});
		} else {
			this.actual_img   = localStorage.getItem('avatar');
		}
  }

  openShare(i){
    this.cardShare[i] = !this.cardShare[i]
  }

}
