import { Component } from '@angular/core';

@Component({
  selector: 'app-blood-type',
  templateUrl: './blood-type.html',
  styleUrls: ['./blood-type.scss']
})
export class BloodTypeComponent {
  selectedBloodType: string = '';

  constructor() { }
}
