import { Router } from '@angular/router';
import { CostsCentersService } from './../services/costs-centers.service';
import { GeneralComponent } from './../_utils/general/general.component';
import { ContractsService } from './../services/contracts.service';
import { Component, OnInit } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';

import { Observable } from 'rxjs';
import { AppState } from '../app-store/models/state.model';
import { Store } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {
  constructor(
    private contractsService: ContractsService,
    private loginBackgroundService: LoginBackgroundService,
    private generalComponent: GeneralComponent,
    private constCenters: CostsCentersService,
    private router: Router,
    private store: Store<AppState>,
    ) { }
    
  background: string = 'bg-0';
  code: string = '';
  CLIENT_LABEL: string = environment.CLIENT_LABEL;


  loading$: Observable<boolean>;

  ngOnInit() {
    this.background = this.loginBackgroundService.random();
    this.loading$ = this.store.select('loading').pipe(delay(0));


  }

  async _doIdentifyClass() {

    var filter = [
      { field: 'CollegesClasses.id', value: this.code }
    ];

    var checkContract: any = await this.contractsService.getContracts(filter, 'Briefings.CollegesClasses')

    if (checkContract.summary.total == 0) {
      var filter2 = [
        { field: 'CollegesClasses.code', value: this.code }
      ];

      checkContract = await this.contractsService.getContracts(filter2, 'Briefings.CollegesClasses')
    }


    if (checkContract.summary.total == 0) {
      this.generalComponent.setMessage('Ops! Turma não encontrada.')
    } else {
      if (checkContract.items[0].data.contracts_status_id == 2) {
        var briefing = checkContract.items[0].data.Briefings
        var filter3 = [
          { field: 'CostsCenters.name', value: briefing.CollegesClasses.name }
        ]
        const costCenter: any = await this.constCenters.getCostsCenters(filter3)

        if (costCenter.summary.total == 0) {
          this.generalComponent.setMessage('Ops! Centro de custo não encontrado.')
        } else {
          var today =  new Date()
          var dateToday = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
        
          if (briefing.start <= dateToday && briefing.valid_until >= dateToday) {
            var costCenterId = costCenter.items[0].data.id
            const acession = { acession: checkContract.items[0].data, costs_centers_id: costCenterId }
            localStorage.clear()
            localStorage.setItem('acession', JSON.stringify(acession))
            this.router.navigate(['/dashboard/register-user'])

          } else {
            this.generalComponent.setMessage('Ops! Turma fora do período de adesão.')
          }
        }
      } else {
        this.generalComponent.setMessage('Ops! Contrato da turma não assinado.')
      }
    }
  }
}
