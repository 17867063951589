import { Component, OnInit } from '@angular/core';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-view-announcements',
  templateUrl: './view-announcements.component.html',
  styleUrls: ['./view-announcements.component.scss']
})
export class ViewAnnouncementsComponent implements OnInit {
  auth                : any;
  infoAnnouncements   : any[] = [
  {
    title: 'Medidas Covid-19',
    publication: '23 mar',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ex augue, pretium in eros nec, efficitur laoreet lectus. Morbi interdum dui mi, ac congue erat semper bibendum. Sed pharetra risus a egestas ornare.',
    
  },
 ]
  peoplesConfirmed    : any[]=[
    {
      id: '1',
      name: 'Juninho Antonio Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null
    },
    {
      id: '2',
      name: 'Alana Silva',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null

    },
    {
      id: '3',
      name: 'Aurora Lamenha',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '4',
      name: 'Bruno Tavarez',
      img_url: null
    },
    {
      id: '5',
      name: 'Camila Mendes',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '6',
      name: 'Camila Oliveira',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '7',
      name: 'Flávia Shnowtz',
      img_url: null
    },
    {
      id: '8',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '9',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '10',
      name: 'Rodrigo Soares',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '11',
      name: 'Pedro Rafael',
      img_url: null
    },
    {
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
  ]

  constructor(
    private authService: AuthMainService,


  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
    
    this.auth.user.isComition = false;
  }

}
