import { Md5 } from 'md5-typescript';
import { RestService } from './rest.service';

export class StoreSalesService extends RestService {
    private _url = 'StoresSales';
    private _urlByHash = 'StoresSales/getSaleByHash';
    private _urlGenerateAll = 'StoresSales/generateAllBills';


    async getSoreSales(filter_data, contain_string = '', order: string = '', limit: number = 200, page: number = 0) {
        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if (item.operator == null || item.operator == '') {
                qs += item.field + '|' + item.value + ';'
            } else {
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, contains: contain_string, order: order } });
    }

    async getStoreSale(id, contains) {
        return await this.getApi(this._url + '/' + id, { params: { contains } });
    }

    async getStoreSaleByHash(saleHash: string) {
        return await this.getApi(this._urlByHash, { params: { hash: saleHash} });
    }

    async execStoreSalePayment(saleData: any) {
        return await this.postApi(this._url + '/execSalePayment', saleData);
    }

    createSale(saleData) {
        return this.postApi(this._url + '/execSale', saleData);
    }
    async generateAllBills(id: any) {
        return await this.postApi(this._urlGenerateAll, JSON.stringify({id: Md5.init(id)}))
      }
}