import { Component, OnInit } from '@angular/core';
import { PartnersSubscriberService } from 'src/app/services/partners-subscriber.service';

@Component({
  selector: 'app-card-subscriber',
  templateUrl: './card-subscriber.component.html',
  styleUrls: ['./card-subscriber.component.scss']
})
export class CardSubscriberComponent implements OnInit {
  userData: any 
  subscribe: any = ''
  constructor(
    private partnersSubscriberService: PartnersSubscriberService,
  ) { }

  ngOnInit() {
    const prefs = localStorage.getItem('auth')
    this.userData = JSON.parse(prefs)
    this.load()
  }

  async load() {
    const filter = [
      { field: 'SignaturesUsers.users_id', value: this.userData.user.id },
    ]

    const subscriber:any = await this.partnersSubscriberService.getPartnersSubscribers(filter)
    this.subscribe = subscriber.items[0].data
  }

}
