import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import { LoadingClose, LoadingShow } from '../app-store/actions/loading.actions';
import { AppState } from '../app-store/models/state.model';

@Injectable()
export class LoadingService {

  constructor(private store: Store<AppState>) {}

  show(): void{
    this.store.dispatch(new LoadingShow());
  }

  close(): void{
    this.store.dispatch(new LoadingClose());
  }

}