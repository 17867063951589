import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-packge',
  templateUrl: './select-packge.component.html',
  styleUrls: ['./select-packge.component.scss']
})
export class SelectPackgeComponent implements OnInit {
  payment: boolean = false
  contract: boolean = false
  teste: boolean = false
  termsAcepted: boolean = false
  selectedBill: boolean = false
  selectedCard: boolean = false
  CLIENT_NICKNAME: string = environment.CLIENT_NICKNAME;
  constructor() { }

  ngOnInit() {
    this._loadPackege()
  }

  async _loadPackege() {
    var acession: any = localStorage.getItem('acession')
    // var briefingId = acession.Briefings.id
  }

  changecontract() {
    this.contract = !this.contract
  }
  changepayment() {
    this.payment = !this.payment
  }

  aceptTerms() {
    this.termsAcepted = !this.termsAcepted
  }



}
