import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-not-found-another-image',
  templateUrl: './layout-not-found-another-image.component.html',
  styleUrls: ['./layout-not-found-another-image.component.scss']
})
export class LayoutNotFoundAnotherImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
