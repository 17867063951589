import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-left-side-bar-guest',
  templateUrl: './left-side-bar-guest.component.html',
  styleUrls: ['./left-side-bar-guest.component.scss']
})
export class LeftSideBarGuestComponent implements OnInit {
  actual_url: string = null;
  showMenuMobile: boolean = false

  constructor(
    private authService: AuthMainService,
    private router: Router
  ) { }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenu(this.router.routerState.snapshot.url);
      }
    });
    this.setActiveMenu(this.router.routerState.snapshot.url);
  }

  logout() {
    this.authService.unsetUser();
    this.router.navigate(['/login']);
  }

  private setActiveMenu(url) {
    this.actual_url = url;
  }

  closeLeftMenu() {
    this.showMenuMobile = false
  }

}
