import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Auth } from '../model/auth.model';
import { User } from '../model/user.model';
import { RestService } from './rest.service';
import { UserService } from './user.service';
import { ImageService } from './image.service';
import { LoadingService } from './loading.service';


@Injectable()
export class AuthMainService extends RestService {

	private loggedIn = new BehaviorSubject<boolean>(false); // {1}
	auth: Auth;
	authClass: {
		class: any,
		isLogged: boolean
	} = {
			class: [],
			isLogged: false
		};
	private _loginUrl = 'Users/login';
	private _preLoginUrl = 'Users/preLogin';
	private _loginProcessUrl = 'Classes/login';

	get isLoggedIn() {
		return this.loggedIn.asObservable(); // {2}
	}

	constructor(
		protected _http: HttpClient,
		private imageService: ImageService,
		private userService: UserService,
		protected loadingService: LoadingService
	) {
		super(_http, loadingService);
		if (this.isLogged()) {
			this.auth = this.getUser();
			this.loggedIn.next(true);
		}
	}

	async setClass(classe) {
		let auth = new Auth();
		let auth1 = <Auth>JSON.parse(localStorage.getItem('auth'));

		auth.user = classe;
		auth.isLogged = true;
		Object.assign(auth, auth1);

		localStorage.setItem('auth', JSON.stringify(auth));
		this.loggedIn.next(true);
		return true;
	}

	async setUser(user: User, permissions: any = {}) {
		localStorage.removeItem('avatar');
		let auth = new Auth();
		let auth1 = <Auth>JSON.parse(localStorage.getItem('auth'));
		if (user.tokenUser && user.tokenUser != '') {
			localStorage.setItem('tokenUser', user.tokenUser);
		}
		auth.user = user;
		auth.isLogged = true;
		Object.assign(auth, auth1);

		localStorage.setItem('auth', JSON.stringify(auth));
		this.loggedIn.next(true);
		return true;

	}

	public getAvatar(user_id): any {
		const apiUrl = localStorage.getItem('api_url')
		return this.imageService.getImages({ entity: 'user', entity_id: user_id })
			.then(response => {

			
				if (response.summary.process == false || response.summary.message == 'error') {
					localStorage.removeItem('avatar');
					return true;
				} else {
					if (response.items.length > 0 && response.items[response.items.length - 1].data.src != '') {
						localStorage.setItem('avatar', `${apiUrl}${response.items[response.items.length - 1].data.src}`);
						return true;
					}
				}
			});
	}

	public getUser(): Auth {
		let auth: string | Auth = localStorage.getItem('auth');
		if (auth) {
			auth = <Auth>JSON.parse(auth);
		} else {
			auth = new Auth();
		}
		return auth;
	}

	public isLogged(): boolean {
		return this.getUser().isLogged;
	}

	public unsetUser() {
		// kill user session

		let auth1 = <Auth>JSON.parse(localStorage.getItem('auth'));
		if (auth1 && auth1.user && auth1.user.activeSession != 0)
			this.userService.killSession(auth1.user.activeSession);

		localStorage.removeItem('auth');
		localStorage.removeItem('token');
		localStorage.removeItem('tokenUser');
		localStorage.removeItem('finance_resp');
		localStorage.removeItem('tokenUserExpires');
		localStorage.removeItem('sessionHash');
		localStorage.removeItem('avatar');
		localStorage.removeItem('parallel-login');
		this.loggedIn.next(false);
	}

	public login(user: string, password: string): any {
		let data = JSON.stringify({ login: user, password: password });
		let response = this.postApi(this._loginUrl, data);
		return response;
	}

	public preLogin(user: string, password: string): any {
		let data = JSON.stringify({ login: user, password: password });
		let response = this.postApi(this._preLoginUrl, data);
		return response;
	}

	public loginProcess(user: string, password: string): any {
		let data = JSON.stringify({ login: user, password: password });
		let response = this.postApi(this._loginProcessUrl, data);
		return response;
	}

	public logout() {
		this.unsetUser();
	}

}
