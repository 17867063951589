import { Component, OnInit } from '@angular/core';
import { AuthMainService } from '../../services/auth.service';

@Component({
  selector: 'profile-desc',
  templateUrl: './profile-desc.component.html'
})
export class ProfileDescComponent implements OnInit {

	actual_img: string = '';
	auth: any;
	show_name: string =  ''

	constructor(
		private authService: AuthMainService,
	) {}

	ngOnInit() {
		if(!localStorage.getItem('auth')){
			this.show_name = 'Formore'
			return
		}
		this.auth = JSON.parse(localStorage.getItem('auth'));
		if ( this.auth.user.nickname != null ) {
			this.show_name	= this.auth.user.nickname;
		} else {
			let full_name	= this.auth.user.name.split(" ");
			this.show_name	= full_name[0];
		}
		if ( !localStorage.getItem('avatar') ) {
			this.authService.getAvatar(this.auth.user.id).then(result => {
				this.actual_img   = localStorage.getItem('avatar');
			});
		} else {
			this.actual_img   = localStorage.getItem('avatar');
		}
	}
}
