import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  tab                 :any = 'grade';
  options             :any = [];
  optionsMobile       :any = [];
  pesquiseMobile      :boolean = false;

  // Variavel mudar estado de exibição quando tem docs e quando não tem, 0/1. 
  docs                :boolean = true;

  docsList: any[]= [
    {
      imageUrl: "/assets/imgs/dashboard/image-pdf.png",
      title: 'Termos de adesão',
      date: "10/06/2021"
    },
    {
      imageUrl: "/assets/imgs/dashboard/image-xls.png",
      title: 'Contrato de turma',
      date: "10/06/2021"
    },
    {
      imageUrl: "/assets/imgs/dashboard/image-doc.png",
      title: 'Orçamento Baile',
      date: "10/06/2021"
    },
    {
      imageUrl: "/assets/imgs/dashboard/image-doc.png",
      title: 'Orçamento Baile',
      date: "10/06/2021"
    },
    {
      imageUrl: "/assets/imgs/dashboard/image-xls.png",
      title: 'Orçamento Baile',
      date: "10/06/2021"
    }
  ]
  constructor() {}

  ngOnInit() {}

  openPesquise() {
    this.pesquiseMobile = !this.pesquiseMobile
  }

  openOptions(i) {
    this.options[i] = !this.options[i]
  }

  optioMobiles(i) {
    this.optionsMobile[i] = !this.optionsMobile[i]
  }

  select(tab: string) {
    this.tab  = tab;
    return false;
  }
}
