import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractQuotaService } from '../services/contract-quota.service';
import { GeneralComponent } from '../_utils/general/general.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../app-store/models/state.model';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
})

export class DashboardLayoutComponent implements OnInit {

    actual_url: string = null;
    selected: any = [];
    selected1: any = [];

    loading$: Observable<boolean>;

    constructor(
        private router: Router,
        private store: Store<AppState>,
    ) { }

    ngOnInit() {

        this.loading$ = this.store.select('loading').pipe(delay(0));
    }


    showButton(index, local = 'one') {
        if (local == 'one')
            this.selected[index] = true;
        else
            this.selected1[index] = true;
    }

    hideButton(index, local = 'one') {
        if (local == 'one')
            this.selected[index] = false;
        else
            this.selected1[index] = false;
    }

}