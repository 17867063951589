import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store-guest-purchases-list',
  templateUrl: './store-guest-purchases-list.component.html',
  styleUrls: ['./store-guest-purchases-list.component.scss']
})
export class StoreGuestPurchasesListComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  guestName: string
  guestPurchases: any[] = []
  
  ngOnInit() {
    this.load()
  }

  async load() {
    const getGuestData = JSON.parse(localStorage.getItem('guestList'))

    this.guestName = getGuestData.name
    this.guestPurchases = getGuestData.data.StoresSalesPurchases


    console.log(this.guestPurchases)
  }

  showGuestPurchase(pdata: any){
    pdata.guestName = this.guestName

    localStorage.removeItem('guestPurchase')
    localStorage.setItem('guestPurchase', JSON.stringify(pdata))

    this.router.navigate(['/dashboard/guest-purchase-detail'])
  }

}
