import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(

  ) { }
  favorite            : boolean = false;

  arquivo             : any = [];
  comentdelet         : any = [];
  opt                 : any = [];
  auth                : any     ;
  resp                : any = [];
  events              : any[] = [
    {
      name: "Evento 1=da bebeideira",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ex augue, pretium in eros nec, efficitur laoreet lectus. Morbi interdum dui mi, ac congue erat semper bibendum. Sed pharetra risus a egestas ornare. ",
      date: new Date(),
      location: "James Bar | Avenida Batel, 748"
    }
  ]

  possibles_status:any =  {
    confirmed: 1,
    declined: 2
  }

  peoplesConfirmed : any[]=[
    {
      id: '1',
      name: 'Juninho Antonio Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null
    },
    {
      id: '2',
      name: 'Alana Silva',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null

    },
    {
      id: '3',
      name: 'Aurora Lamenha',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '4',
      name: 'Bruno Tavarez',
      img_url: null
    },
    {
      id: '5',
      name: 'Camila Mendes',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '6',
      name: 'Camila Oliveira',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '7',
      name: 'Flávia Shnowtz',
      img_url: null
    },
    {
      id: '8',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '9',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '10',
      name: 'Rodrigo Soares',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '11',
      name: 'Pedro Rafael',
      img_url: null
    },
    {
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
  ]

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
    this.auth.user.isComition = false; // QUANDO FOR COMISSÂO == TRUE; QUANDO FOR FORMANDO == false.
  }

  changeStatus(event, status: number){
    event.status = status
  }
  optOpen(i) {
    this.opt[i] = !this.opt[i]
  }
  deleteComent(i) {
    this.comentdelet[i] = !this.comentdelet[i]
  }
  openResp(i) {
    this.resp[i] = !this.resp[i]
  }

  clickFavorite() {
    this.favorite = !this.favorite
  }
  openArquivo(i) {
    this.arquivo[i] = !this.arquivo[i]
  }
  
}
