import { Component } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';
import { AuthMainService } from '../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../model/user.model';
import { Validation } from '../model/validation.model';
import { ContractQuotaService } from '../services/contract-quota.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	providers: [LoginBackgroundService],
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	background: string = 'bg-0';
	errorMessage: string = '';
	errorMessagePass: string = '';
	user: User = new User();
	clientLabel = environment.CLIENT_NICKNAME;

	validation: Validation = new Validation();

	constructor(
		private loginBackgroundService: LoginBackgroundService,
		private authService: AuthMainService,
		private contractsQuotas: ContractQuotaService,
		private router: Router
	) { }

	ngOnInit() {
		this.background = this.loginBackgroundService.random();
	}

	async login() {
		if (this.user.email != null || this.user.password != null) {
		  try {
			const response = await this.authService.login(this.user.email, this.user.password);
			if (response.summary.process && response.summary.process == true) {
			  this.authService.getAvatar(response.data.id);
			  localStorage.setItem('tokenUser', response.data.tokenUser);
			  localStorage.setItem('tokenUserExpires', response.data.tokenUserExpires);
			  const r = await this.authService.setUser(response.data);
			  if (r) {
				let blocked = false;
	  
				const studentFilter = [
				  { field: 'ContractsQuotas.user_id', value: response.data.id }
				];
	  
				const result = await this.contractsQuotas.getQuotas(studentFilter, 'Contracts.Briefings.CollegesClasses', '', 0, '', 1);
				if (result.summary.total > 0) {
				  localStorage.setItem("quota", JSON.stringify({
					contract_id: result.items[0].data.contract_id,
					college_class: {
					  id: result.items[0].data.Contracts.Briefings.CollegesClasses.id,
					  name: result.items[0].data.Contracts.Briefings.CollegesClasses.name
					}
				  }));
				  this.router.navigate(['/dashboard/financial']);
				} else {
				  blocked = true;
				}
	  
				const financeRespFilter = [
				  { field: 'ContractsQuotas.finance_resp_id', value: response.data.id }
				];
	  
				const financeRespResult = await this.contractsQuotas.getQuotas(financeRespFilter, 'Contracts.Briefings.CollegesClasses', '', 0, '', 1);
				if (financeRespResult.summary.total > 0) {
				  localStorage.setItem('finance_resp', JSON.stringify({ finance_resp_access: true, original_user_id: financeRespResult.items[0].data.user_id }));
				  localStorage.setItem("quota", JSON.stringify({
					contract_id: financeRespResult.items[0].data.contract_id,
					college_class: {
					  id: financeRespResult.items[0].data.Contracts.Briefings.CollegesClasses.id,
					  name: financeRespResult.items[0].data.Contracts.Briefings.CollegesClasses.name
					}
				  }));
				  this.router.navigate(['/dashboard/financial']);
				} else {
				  blocked = true;
				}
	  
				if (blocked) {
				  this.validation.fields.push('passwd_error');
				  this.errorMessagePass = 'Você não possui adesões para visualizar';
				  this.authService.logout();
				}
			  }
			} else {
			  this.validation.fields.push('passwd_error');
			  this.errorMessagePass = 'Ops, senha incorreta';
			  localStorage.removeItem('tokenUser');
			}
		  } catch (error) {
			console.log(error)
		  }
		}
	  }
	  

}
