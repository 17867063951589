import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable()
export class ContractQuotaService extends RestService {


    private _url = 'ContractsQuotas';
    
    constructor(protected _http: HttpClient,
        protected loadingService: LoadingService) {
        super(_http, loadingService);
    }
    
    insertQuota(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateQuota(quota: any) : any {
        let data    = JSON.stringify(quota);
        return this.putApi(this._url + '/' + quota.id, data);
    }
    
    getQuotas(filter_data: Array<any>, contains: string = '', order_string: string = '+ContractsQuotas.id', page: number = 0, fields: string = '', limit: number = 20, group=''): any {
        let qs = '';
        let paging  = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }

        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields, group: group}});
    }
}