export class FormTeste {
  titulo: string
  local: string
  endereco: string
  data: number
  horario: number
  venda: string
  descricao: string

  constructor(
    titulo: string = null,
    local: string = null,
    endereco: string = null,
    data: number = null,
    horario: number = null,
    venda: string = null,
    descricao: string = null
  ){
    this.titulo = titulo
    this.local = local
    this.endereco = endereco
    this.data = data
    this.horario = horario
    this.venda = venda
    this.descricao = descricao
  }
}
