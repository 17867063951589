import { Component, OnInit } from '@angular/core';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  auth                : any;
  cardShare           : any = []
  status              : any = [];
  subMenu             : any = 'subMenuShare';
  
  possibles_status:any =  {
    confirmed: 1,
    declined: 2
  }
  
  events:any[] = [
    {
      name: "Evento 1=da bebeideira",
      status: null,
      date: new Date(),
    },
    {
      name: "Evento de missa da igreja do SENHOR",
      status: null,
      date: new Date(),
    },
    {
      name: "Evento de missa da igreja do SENHOR do ceu",
      status: null,
      date: new Date(),
    },
    {
      name: "Evento de missa da igreja do SENHOR das trevas",
      status: null,
      date: new Date(),
    },
    {
      name: "Evento de missa da igreja do SENHOR mares infernais",
      status: null,
      date: new Date(),
    },
  ]

  constructor(
    private authService: AuthMainService,

  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
    this.auth.user.isComition = true;

  }
  selectSub(subMenu: string) {
    this.subMenu  = subMenu;
    return false;
  }

  openShare(i){
    this.cardShare[i] = !this.cardShare[i]
  }

  openStatus(i) {
    this.status[i] = !this.status[i]
  }

  changeStatus(event, status: number){
    event.status = status
  }
}
