import { Component } from '@angular/core';
import { ContractQuotaService } from '../services/contract-quota.service';
import { Package } from '../model/package.model';
import { Class } from '../model/class.model';
import { ReceivablesService } from '../services/receivables.service';
import { Receivable } from '../model/receivable.model';
import { ContractQuota } from '../model/contract_quota.model';
import { GroupsUsersService } from '../services/groups-users.service';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent {

  constructor(
    private contracQuotaService: ContractQuotaService,
    private receivablesService: ReceivablesService,
    private groupsUsersService: GroupsUsersService,
    private statsService: StatsService
  ) { }

  auth: any;
  package: Package = new Package()
  class: Class = new Class()
  admin: boolean = false;
  receivables: Receivable[] = []
  delayedReceivables: Receivable[] = []
  contractQuota: ContractQuota = new ContractQuota();
  
  projects: any = [];
  
  tab: any = 'personal_data';
  total_waiting: number = 0;
  total_delayed: number = 0;
  total_paid: number = 0;
  total: number = 0;
  percent_paid: any = 0;
  percent_delayed: any = 0;
  percent_waiting: any = 0;
  total_receivables: number = 0

  total_waiting_commission: number = 0;
  total_delayed_commission: number = 0;
  total_paid_commission: number = 0;
  total_commission: number = 0;
  percent_paid_commission: any = 0;
  percent_delayed_commission: any = 0;
  percent_waiting_commission: any = 0;
  
  isColapsed: any = []
  classId: string
  isColapsed2: any = []
  viewBill: boolean = false;
  openMessage: boolean = false;
  lateInstalment: boolean = false;

  dataPayments: any = []
  dataWaitings: any = []
  dataDelayeds: any = []
  dataPreview: any = []
  test: any = []

  checkCurrentMonthInstallments: any[] = []
  loadGraph: any = false

  async ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
    this.checkAdmin()
    await this.loadProjects();
    this.loadGraph  = true;
  }

  async checkAdmin() {
    var filterAdmin = [
      { field: 'GroupsUsers.user_id', value: this.auth.user.id },
      { field: 'GroupsUsers.group_id', value: '9' }
    ];

    const check: any = await this.groupsUsersService.getGroupsUsers(filterAdmin);
    if (check.summary.total > 0) {
      this.admin = true;
      var filter = [
        { field: 'ContractsQuotas.user_id', value: this.auth.user.id },
        { field: 'ContractsQuotas.contracts_quotas_status_id', value: '1' },
        { field: 'Packages.type', value: 'default' }
      ];
      const getClass = await this.contracQuotaService.getQuotas(filter, 'Packages.Briefings')

      if (getClass.summary.total > 0) {
        this.classId = getClass.items[0].data.Packages.Briefings.colleges_classes_id
      }

      await this._loadStats()
      await this.getDelayers()
    }
  }

  async _loadStats() {
    const filter = [
      {field: 'class_id', value: this.classId}
    ]

    const getSituation: any = await this.statsService.situation(filter)
    
    const checkPaid = getSituation['items'].find(item => item.status == 'Recebido')
    if (checkPaid) {
      this.total_paid_commission = +getSituation['items'].find(item => item.status == 'Recebido').subtotal
    }

    const checkDelayed = getSituation['items'].find(item => item.status == 'Vencido')
    if (checkDelayed) {
      this.total_delayed_commission = +getSituation['items'].find(item => item.status == 'Vencido').subtotal
    }

    const checkOpen = getSituation['items'].find(item => item.status == 'Em Aberto')
    if (checkOpen) {
      this.total_waiting_commission = +getSituation['items'].find(item => item.status == 'Em Aberto').subtotal
    }

    this.total_commission = this.total_paid_commission + this.total_delayed_commission + this.total_waiting_commission
    
    this.percent_paid_commission = Math.round((this.total_paid_commission / this.total_commission) * 100);

    this.percent_delayed_commission = Math.round(( this.total_delayed_commission / this.total_commission) * 100);
    this.percent_waiting_commission = Math.round((this.total_waiting_commission / this.total_commission) * 100);
  }

  async getDelayers(){
    const filter = [
      {field: 'class_id', value: this.classId}
    ]

    const getDelayers: any = await this.statsService.delayers(filter)

    this.delayedReceivables = getDelayers.items.length > 0 ? getDelayers.items : []

  }

  async loadProjects() {
    // this.loadingService.show()
    const quotas = []

    const userId = this.auth.user.id
    const filter = [
      { field: 'ContractsQuotas.user_id', value: userId },
      { field: 'ContractsQuotas.contracts_quotas_status_id', operator: 'IN', value: '1,3' },
    ]
    
    const getQuotas = await this.contracQuotaService.getQuotas(filter, 'Contracts.Briefings.CollegesClasses;Packages', '-ContractsQuotas.id', 0, '', 1000)
    if (getQuotas.summary.total > 0) {
      
      getQuotas.items.map((qt: any) => {
        this.projects.push({ 
          id: qt.data.id, 
          packageName: qt.data.Packages.name, 
          collegeClassName: qt.data.Contracts.Briefings.CollegesClasses.name,
          created: qt.data.created,
          totalPerStudent: qt.data.Packages.total_per_student
        })

        quotas.push(qt.data.id)
      })

      const filterR = [
        {field: 'Receivables.users_id', value: userId },
        {field: 'Receivables.stores_sales_id', value: 'NULL'},
        {field: 'ContractsQuotas.contracts_quotas_status_id', operator: 'IN', value: '1,3' },
        {field: 'Receivables.contracts_quotas_id', operator: 'IN', value: quotas.join(',') }
      ];

      const quotasReceivables = await this.receivablesService.getReceivables(filterR, 'ContractsQuotas.Packages')

      const openInstallments = []

      if (quotasReceivables.summary.total > 0) {
        const items: Array<any> = quotasReceivables.items;

        this.projects.map(proj => {
          proj.totalReceivables = items.filter(item => item.data.contracts_quotas_id == proj.id).length

          return proj
        })
  
        items.map(item => {
          const baseValue = item.data.base_value
          this.total += baseValue;
  
          if (item.data.receivables_status_id == 3) {
            this.total_paid += baseValue;
          }
          if (item.data.receivables_status_id == 5) {
            this.total_delayed += baseValue;
          }
          if (item.data.receivables_status_id == 1 || item.data.receivables_status_id == 2) {
            this.total_waiting += baseValue;
            openInstallments.push(item.data)
          }

          this.receivables.push(item.data)
        })

        this.percent_paid = Math.round((this.total_paid / this.total) * 100);
        this.percent_delayed = Math.round(( this.total_delayed / this.total) * 100);
        this.percent_waiting = Math.round((this.total_waiting / this.total) * 100);
        
        if(this.total_delayed > 0){
          // this.lateInstalment = true
        }
      }

      const today = new Date()
      const month = today.getMonth() + 1
      const year = today.getFullYear()

      this.checkCurrentMonthInstallments = openInstallments.filter(rec => {
        const [y,m,] = rec.due_date.split('-')
        if(month == m && year == y)
          return true
      })

      // const tests = [
      //   {paid: 27, waiting: 72, delayed: 1},
      // ]
      // this.test = tests;
      // this.loadingService.close()
      // this.filterProject(0);
    }
  }

  select(tab: string) {
    this.tab = tab;
    return false;
  }

}
