import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-poll',
  templateUrl: './add-poll.component.html',
  styleUrls: ['./add-poll.component.scss']
})
export class AddPollComponent implements OnInit {
  isColapsed         : boolean = false
  image              : boolean = true
  openEdit           : boolean = false 
  days               : any[] = []  
  
  peoplesSelected    : any[]=[
    {
      id: '1',
      name: 'Juninho Antonio Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null
    },
    {
      id: '2',
      name: 'Alana Silva',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null

    },
    {
      id: '3',
      name: 'Aurora Lamenha',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '4',
      name: 'Bruno Tavarez',
      img_url: null
    },
    {
      id: '5',
      name: 'Camila Mendes',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '6',
      name: 'Camila Oliveira',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '7',
      name: 'Flávia Shnowtz',
      img_url: null
    },
    {
      id: '8',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '9',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '10',
      name: 'Rodrigo Soares',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '11',
      name: 'Pedro Rafael',
      img_url: null
    },
    {
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },{
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
  ]
  constructor() { }

  ngOnInit() {
  }


  openDuration() {
    this.isColapsed = !this.isColapsed
  }
  openEditBox() {
    this.openEdit = !this.openEdit
  }

  loadDays() {
    const daysTotal = 30
    for(let i=0; i<daysTotal; i++){
      this.days.push(i.toString().padStart(2, '0'))
    }
  }

}
