export class Partner {
    id: number;
    name: string;
    roles:string
    phone: string;
    whatsapp: string = null;
    description: string;
    discount_amount: number;
    distance: number;
    images: Array <any>;
    address: string;
    cover_image: string;
    number: number;
    neighborhood: string;
    site_url: string;
    instagram_url: string;
    discount_type: any;
    constructor(
        id: number = null, 
        name: string = null, 
        roles:string = null,
        phone: string = null,
        whatsapp: string = null,
        description: string = null, 
        discount_amount: number = null, 
        distance: number = null, 
        images: Array<any> = [],
        address: string = null,
        number: number = null,
        cover_image: string = null,
        neighborhood: string = null,
        site_url: string = null,
        instagram_url: string = null,
        discount_type: any = null,
    ) {
        this.id = id;
        this.name = name;
        this.roles = roles;
        this.phone = phone;
        this.whatsapp = whatsapp;
        this.description = description;
        this.discount_amount = discount_amount;
        this.distance = distance;
        this.images = images;
        this.address = address;
        this.number = number;
        this.cover_image = cover_image;
        this.neighborhood = neighborhood;
        this.site_url = site_url;
        this.instagram_url = instagram_url;
        this.discount_type = discount_type;
    }
    
}