import { Component, OnInit } from '@angular/core';
import { ContractQuotaService } from '../services/contract-quota.service';
import { StoresConfigsCollegesClassesService } from '../services/stores-configs-colleges-classes.service';
import { PaymentsMethodsService } from '../services/payments-methods.service';
import { CostsCentersService } from '../services/costs-centers.service';
import { StoreSalesService } from '../services/store-sales.service';
import { GeneralComponent } from '../_utils/general/general.component';
import { Router } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { RestService } from '../services/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoresProductsCategoriesService } from '../services/store_product_category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public creditCardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  public cardDueDateMask = [/\d/, /\d/, '/', /\d/, /\d/];
  public cardCvvMask = [/\d/, /\d/, /\d/];
  public maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
	public maskPhone = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]


  toggleParcel:boolean = false
  formPaymentC: boolean = false;
  formPaymentB: boolean = true;
  card: boolean = false;
  guest: boolean = false;
  total: number = 0.0;
  qty: number = 1;
  storeConfiguration: any = {}
  quota: any = {}
  _paymentType: number = 7
  installments: Array<any> = []
  selectOpen: boolean = false
  cartProd: any = []
  _isBoletoEnabled: boolean = false;
  _isCreditCardEnabled: boolean = false;
  // variables: any = {}
  apiUrl: string = ''
  auth: any
  qtyInstallments: number = 0
  step: number = 1;
  notRegistered: boolean = false
  creditCardNumber: string
  creditCardName: string
  creditCardDueDate: string
  creditCardCvv: string
  barWidht: number = 0
  newCreditCardForm: FormGroup;
  creditCardFormSubmitted: boolean = false;
  registered:any
  
  constructor(
    private contractQuotaService: ContractQuotaService,
    private storesConfigsCollegesClassesService: StoresConfigsCollegesClassesService,
    private paymentsMethodsService: PaymentsMethodsService,
    private costsCentersService: CostsCentersService,
    private storeSalesService: StoreSalesService,
    private generalComponent: GeneralComponent,
    private router: Router,
    private restService: RestService,
    private _fb: FormBuilder,
    private storesProductsCategoriesService: StoresProductsCategoriesService,
    private modalService: NgbModal

  ) { }

  ngOnInit() {
    this.newCreditCardForm = this._fb.group({
      creditCardName: ['', [Validators.required, Validators.minLength(3)]],
      creditCard: [null, [Validators.required, Validators.minLength(17), Validators.maxLength(19)]],
      // creditCard: [null, [CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    });

    this.load()
    this._checkPaymentTypeStatus();
  }
  nextStep() {
    if (this.step == 1) {
      this.step = 2
      this.barWidht = 25
      return;
    }

    if (this.step == 2) {
      this.step = 3
    }


    
  }
  async load() {
    this.apiUrl = localStorage.getItem('api_url')
    this.auth = JSON.parse(localStorage.getItem('auth'))

    const filter = [
      { field: 'ContractsQuotas.user_id', value: this.auth.user.id },
    ]

    const contract: any = await this.contractQuotaService.getQuotas(filter, 'Contracts.Briefings.CollegesClasses.StoresConfigs.StoresCommercialRules;Contracts.FinanceUnits.Companies.DefaultGateway;Contracts.FinanceUnits.Companies', '-ContractsQuotas.id')

    this.quota = contract.items[0].data

    this._paymentType = (this.quota.Contracts.Briefings.CollegesClasses.actual_max_installments == 0) ? 8 : 7

    const collegeClass = this.quota.Contracts.Briefings.CollegesClasses

    var checkLength: Array<any> = collegeClass.StoresConfigs
    if (checkLength.length > 0) {
      this.storeConfiguration = collegeClass.StoresConfigs[0]
    } else {
      const filter2 = [
        { field: 'StoresConfigsCollegesClasses.colleges_classes_id', value: 1 }
      ]
      const genericStoreConfig: any = await this.storesConfigsCollegesClassesService.getStoresConfigsCollegesClasses(filter2, 'StoresConfigs.StoresCommercialRules')
      this.storeConfiguration = genericStoreConfig.items[0].data.StoresConfigs
    }

    this._loadCart()
  }

  async _checkPaymentTypeStatus() {
    const filter = [
      { field: 'PaymentsMethods.id', operator: 'IN', value: "7,8" }
    ]

    const check: any = await this.paymentsMethodsService.getPaymentsMethods(filter)

    if (check.summary.total > 0) {
      const checkBillStatus = check.items.filter(item => item.data.id == 7)
      const checkCardStatus = check.items.filter(item => item.data.id == 8)

      checkBillStatus.map(item => {
        if (item.data.status == 'a') {
          this._isBoletoEnabled = true
        }
      })

      checkCardStatus.map(item => {
        if (item.data.status == 'a') {
          this._isCreditCardEnabled = true
        }
      })

      if (!this._isBoletoEnabled && this._isCreditCardEnabled) {
        this._paymentType = 8
      }
    }
  }

  async _loadCart() {
    const getCart = localStorage.getItem('cart')
    this.cartProd = JSON.parse(getCart)

    this.calcValues()
  }

  calcValues() {
    this.total = 0.0;
    this.cartProd.map(pr => {
      var price = pr.price
      var quant = pr.quantity
      var qtotal = price * quant

      this.total += qtotal
    })

    this.qty = (this.storeConfiguration && this.storeConfiguration.max_installments > 0) ? this.storeConfiguration.max_installments : this.quota.Contracts.Briefings.CollegesClasses.actual_max_installments

    if (this._paymentType == 8) {
      this.qty = 12
    }

    var allowedInstallments = false;

    while (!allowedInstallments) {
      this.installments = []
      for (var i = 0; i <= this.qty; i++) {
        const installment: any = {}

        var instValue = (this.total / i)
        installment.original_value = instValue
        installment.discount = false
        installment.juros = false
        installment.tax = 0
        installment.diff = 0

        const checkInstallmentCondition = this.storeConfiguration.StoresCommercialRules.filter(element => element.payments_methods_id == this._paymentType && i == element.installment)
        if (checkInstallmentCondition.length > 0) {
          const instCond = checkInstallmentCondition[0]
          const originValue = instValue

          if (instCond.discount != null && instCond.discount > 0) {
            instValue = instValue - ((instCond.discount / 100) * instValue)
            installment.tax = instCond.discount
            installment.discount = true
            installment.juros = false
            installment.diff = originValue - instValue
          }

          if (instCond.juros != null && instCond.juros > 0) {
            instValue = instValue + ((instCond.juros / 100) * instValue)
            installment.tax = instCond.juros
            installment.juros = true
            installment.diff = originValue - instValue
          }
        }

        installment.value = instValue
        if (installment.value != Infinity) {
          this.installments.push(installment)
        }
      }

      const verifyMinInst = this.installments.filter(element => {
        return element.value < this.storeConfiguration.min_value
      })

      if (verifyMinInst.length > 0) {
        this.qty--
      } else {
        allowedInstallments = true
      }
    }
  }

  changeQuantity(op, item) {
    if (op == '+') {
      if (item.availability > 0 && item.quantity >= item.availability) {
        item.quantity = item.availability
      } else {
        item.quantity += 1
      }
    } else {
      item.quantity -= 1
      if (item.quantity < 1) {
        this.cartProd = this.cartProd.filter((element) => element.id != item.id)
      }
    }

    if (item.quantity > 0) {
      if (!this.cartProd.length) this.cartProd.push(item)

      const verifyProductInCart = this.cartProd.filter(prod => prod.id == item.id)

      if (verifyProductInCart.length == 0) {
        this.cartProd.push(item)
      } else {
        const productInCart: any = this.cartProd.find(prod => prod.id == item.id)
        productInCart.quantity = item.quantity
      }
    }

    localStorage.setItem('cart', JSON.stringify(this.cartProd))

    this.calcValues()
  }

  async checkPurchaseLimits() {
    const getData = await this.storesProductsCategoriesService.getStoresProductsAndCategories()

    let cartHasChanged = false

    const prods = []
    if (getData.summary.total > 0) {
      getData.items.map(cats => {
        cats.products.map(apiProd => {
          prods.push(apiProd)
        })
      })
    }

    this.cartProd.map(cartItem => {
      const checkCartItem = prods.find(prod => prod.id == cartItem.id)
      if (!checkCartItem) {
        this.cartProd = this.cartProd.filter(i => i.id != cartItem.id)
        cartHasChanged = true
      } else {
        if (cartItem.quantity > checkCartItem.availability) {
          cartItem.quantity = checkCartItem.availability
          cartHasChanged = true
        }
      }
    })

    if (cartHasChanged) {

      if (this.cartProd.length == 0) {
        this.generalComponent.setMessage('Ops, parece que não temos mais estoque do(s) produto(s) selecionados', 'error', '', 15000)
      }

      if (this.cartProd.length > 0) {
        this.generalComponent.setMessage('Ops, parece que não temos mais a mesma quantidade em estoque de algum(ns) produto(s) selecionados, já atualizamos seu carrinho, da uma conferida lá :)', 'error', '', 15000)
      }

      return localStorage.setItem('cart', JSON.stringify(this.cartProd))
    }

    this.finishPurchase()
  }

  async finishPurchase() {
    if (this.qtyInstallments > 0) {

      if (this._paymentType == 8) {
        this.creditCardFormSubmitted = true

        if (this.newCreditCardForm.status == "INVALID") {
          return this.generalComponent.setMessage("Por favor verifique todos os campos em vermelho");
        }
      }

      const filter = [
        { field: 'CostsCenters.name', value: this.quota.Contracts.Briefings.CollegesClasses.name },
      ]
      const costCenter: any = await this.costsCentersService.getCostsCenters(filter)

      if (costCenter.summary.total > 0) {
        const sale: any = { users_id: this.auth.user.id, total: this.total, contracts_quotas_id: this.quota.id }

        const installmentIndex = this.qtyInstallments - 1
        if (this.installments[installmentIndex].discount == true) {
          sale.discount = this.installments[installmentIndex].diff * this.qtyInstallments
        }

        if (this.installments[installmentIndex].juros == true) {
          sale.juros = this.installments[installmentIndex].diff * this.qtyInstallments
        }
        sale.items = this.cartProd;
        sale.cost_center_id = costCenter.items[0].data.id;

        sale.installment_value = this.installments[installmentIndex].value
        sale.payment_method_id = this._paymentType;
        sale.installments = this.qtyInstallments;
        sale.mode = 'mobile';

        if (this._paymentType == 8) {
          const ip = await this.restService.getClientIp()

          sale["card_number"] = this.newCreditCardForm.value.creditCard.replace(/\D/g, '')
          sale["card_holder_name"] = this.newCreditCardForm.value.creditCardName
          sale["card_expiration"] = this.newCreditCardForm.value.expirationDate
          sale["card_security"] = this.newCreditCardForm.value.cvc
          sale['remote_ip'] = ip
        }

        const createSale: any = await this.storeSalesService.createSale(sale)

        if (createSale.summary.process) {
          localStorage.removeItem('cart')
          return this.router.navigate(['/dashboard/completed-publish'])
        }

        let errMessage = '';
        createSale.summary.errors.map((err: any) => {
          if (err.message) {
            errMessage += err.message + '\r\n';
          } else {
            errMessage += err.description + '\r\n';
          }
        })
        this.generalComponent.setMessage(errMessage, 'error', '', 15000)
      } else {
        this.generalComponent.setMessage('Nenhum centro de custo relacionado a sua turma')
      }
    } else {
      this.generalComponent.setMessage('Por favor selecione o nº de parcelas')

    }
  }

  cleanCart() {
    localStorage.removeItem('cart')
    this.cartProd = []

    this.modalService.dismissAll()
    this.router.navigate(['/dashboard/store'])
  }

  open(content) {
    this.modalService
      .open(content, {
        size: 'md',
        backdrop: 'static'
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );

  }
  openConfirmed(content) {
    this.modalService
      .open(content, {
        size: 'md',
        backdrop: 'static'
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );

  }

  openFormPayB() {
    this._paymentType = 7
    this.calcValues()

    this.formPaymentB = true
    this.formPaymentC = false
    this.card = false;
  }

  openFormPayC() {
    this._paymentType = 8
    this.calcValues()

    this.formPaymentC = true
    this.formPaymentB = false
    this.card = false;
    this.newCard()
  }

  newCard() {
    this.card = true;
    this.formPaymentC = false
    this.formPaymentB = false
  }

  get f1() {
    return this.newCreditCardForm.controls;
  }

  getCardType() {
    const number = this.newCreditCardForm.value.creditCard.replace(/\s/g, "");
    let brand = "unknown";

    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        brand = "Visa";

    // Mastercard
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
     brand = "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        brand = "Amex";


    if(brand == 'Amex'){
      this.creditCardMask = [ /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/ ];
      this.cardCvvMask = [/\d/, /\d/, /\d/, /\d/];
      return
    }

    this.creditCardMask = [ /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/ ];
    this.cardCvvMask = [/\d/, /\d/, /\d/];
  }

}

