import { RestService } from './rest.service';
export class VariablesService extends RestService {
    private _url = 'Variables';

    async getVariables(filter_data: any = null, contains: string = '', order_string: string = '+Variables.name', page: number = 0, fields: string = '', limit: number = 5000, group = '') {
        let qs = '';
        let paging = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }


        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contains, fields: fields, group: group } });
    }
}