import { Injectable } from "@angular/core";

@Injectable()
export class Globals {

  getMonthLabel(month) {
    month = month + 1

    switch (month) {
      case 1: return 'Janeiro'
      case 2: return 'Fevereiro'
      case 3: return 'Março'
      case 4: return 'Abril'
      case 5: return 'Maio'
      case 6: return 'Junho'
      case 7: return 'Julho'
      case 8: return 'Agosto'
      case 9: return 'Setembro'
      case 10: return 'Outubro'
      case 11: return 'Novembro'
      case 12: return 'Dezembro'
    }
  }

  getMonthShortLabel(month) {
    month = month + 1

    switch (month) {
      case 1: return 'Jan'
      case 2: return 'Fev'
      case 3: return 'Mar'
      case 4: return 'Abr'
      case 5: return 'Mai'
      case 6: return 'Jun'
      case 7: return 'Jul'
      case 8: return 'Ago'
      case 9: return 'Set'
      case 10: return 'Out'
      case 11: return 'Nov'
      case 12: return 'Dez'
    }
  }
}