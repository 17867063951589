import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-confirmed',
  templateUrl: './ticket-confirmed.component.html',
  styleUrls: ['./ticket-confirmed.component.scss']
})
export class TicketConfirmedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log(111)
  }

}
