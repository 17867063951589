import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreSalesService } from 'src/app/services/store-sales.service';

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss']
})
export class MyRequestsComponent implements OnInit {
  auth: any
  quota: any
  saleBox: any = []

  constructor(
    private storeSalesService: StoreSalesService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'))
    this.quota = JSON.parse(localStorage.getItem('quota'))

    this.loadSales()
  }
  async loadSales() {
    const filter = [
      { field: 'StoresSales.users_id', value: this.auth.user.id },
      { field: 'StoresSales.stores_sales_status_id', operator: "!=", value: 3 },
    ]

    const apiSales: any = await this.storeSalesService.getSoreSales(filter, 'Receivables.ReceivablesStatus;StoresSalesStatus;FriendStore', '-StoresSales.id')
    if (apiSales.summary.total > 0) {
      apiSales.items.map(async (sale: any) => {

        sale.data.Receivables.map(rec => {
          if (rec.ReceivablesStatus.id == 2) rec.ReceivablesStatus.name = 'vence em'            
          if (rec.ReceivablesStatus.id == 5) rec.ReceivablesStatus.name = 'venceu em'            
          if (rec.ReceivablesStatus.id == 3) rec.ReceivablesStatus.name = 'Pago'            
          return rec
        })
        
        const checkOpen = sale.data.Receivables.find(rec => rec.ReceivablesStatus.id == 2)
        if (checkOpen) {
          sale.data.info = {
            index: sale.data.Receivables.findIndex(rec => rec.id == checkOpen.id) + 1,
            receivable: checkOpen
          }
        }

        const checkDelayed = sale.data.Receivables.find(rec => rec.ReceivablesStatus.id == 5)
        if (checkDelayed) {
          sale.data.info = {
            index: sale.data.Receivables.findIndex(rec => rec.id == checkDelayed.id) + 1,
            receivable: checkDelayed
          }
        }

        sale.data.Receivables.map((status) => {
          if (status.ReceivablesStatus.id == 5) {
            status.ReceivablesStatus.name = "Em atraso"
          }
        })

        const countPaid = sale.data.Receivables.filter(rec => rec.ReceivablesStatus.id == 3).length
        sale.data.totalReceivablesPaid = countPaid

        this.saleBox.push(sale)
      })
    }
  }

  viewSale(sale: any) {
    localStorage.setItem('sale', JSON.stringify(sale))
    this.router.navigate(['/dashboard/sale-detail/' + sale.id])
  }

}
