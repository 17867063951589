import { RegisterUserComponent } from './register-user/register-user.component';
import { SelectPackgeComponent } from './select-packge/select-packge.component';
import { MyRequestsComponent } from './my-requests/my-requests/my-requests.component';
import { CardSubscriberComponent } from './coupons/card-subscriber/card-subscriber.component';
import { VoucherComponent } from './coupons/voucher/voucher.component';
import { CouponsComponent } from './coupons/coupons-list/coupons.component';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { NewPasswordComponent } from './login/new-password.component';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout.component';
import { FinancialComponent } from './dashboard/financial.component';
import { ProfileComponent } from './dashboard/profile.component';
import { AuthGuard } from './auth.guard';
import { OnboardComponent } from './onboard/onboard.component';
import { DocumentsComponent } from './documents/documents.component';
import { ResearchComponent } from './research/research.component';
import { DocumentosComponent } from './dashboard/documentos/documentos.component';
import { ContactComponent } from './dashboard/contact/contact.component';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { EventsComponent } from './events/events.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AddAnnouncementsComponent } from './add-announcements/add-announcements.component';
import { ViewAnnouncementsComponent } from './dashboard/schedule/view-announcements/view-announcements.component';
import { AddPollComponent } from './dashboard/schedule/add-poll/add-poll.component';
import { VotePollComponent } from './dashboard/schedule/vote-poll/vote-poll.component';
import { ProductsViewComponent } from './store-view/store-view.component';
import { StoreComponent } from './store/store.component';
import { CartComponent } from './cart/cart.component';
import { CompletedPublishComponent } from './completed-publish/completed-publish.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { DetailsCouponsComponent } from './coupons/coupons-view/details-coupons.component';
import { SaleDetailComponent } from './sale-detail/sale-detail.component';
import { StoreGuestPurchasesComponent } from './store-guest-purchases/store-guest-purchases.component';
import { StoreGuestPurchasesListComponent } from './store-guest-purchases-list/store-guest-purchases-list.component';
import { StoreGuestPurchaseDetailComponent } from './store-guest-purchase-detail/store-guest-purchase-detail.component';
import { DashboardGuestLayoutComponent } from './dashboard/dashboard-guest.component';
import { NewAccountGuestComponent } from './new-account-guest/new-account-guest.component';
import { PaymentLinkComponent } from './payment-link/payment/payment.component';
import { PaymentLinkKanbanComponent } from './payment-link/payment-link-kanban/payment.kanban.component';
import { PaymentSuccessComponent } from './payment-link/payment-success/payment-success.component';
import { Clients } from 'src/clients/clients.enum';
import { environment } from 'src/environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { IdentificationComponent } from './tickets/identification/identification.component';
import { TicketNotFoundComponent } from './tickets/ticket-not-found/ticket-not-found.component';
import { TicketConfirmedComponent } from './tickets/ticket-confirmed/ticket-confirmed.component';
import { TicketsListComponent } from './tickets/list/list.component';


let routes: Routes = [
	{ path: '', redirectTo: '/dashboard/financial', pathMatch: 'full' },
	{ path: 'research/:hash', component: ResearchComponent, pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'onboard/:key', component: OnboardComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'new-password', component: NewPasswordComponent },
	{ path: 'documents', component: DocumentsComponent },
	{ path: 'politica-de-privacidade', component: DocumentsComponent },
	{ path: 'termos-de-uso', component: DocumentsComponent },
	{ path: 'new-account' , component: NewAccountComponent},
	{ path: 'new-account-guest' , component: NewAccountGuestComponent},
	{ path: 'payment/success' , component: PaymentSuccessComponent},
	{ path: 'payment/:hash' , component: PaymentLinkComponent},
	{ path: 'payment-digital/:hash' , component: PaymentLinkKanbanComponent},
	{ path: 'conviteqr/notFound' , component: TicketNotFoundComponent},
	{ path: 'conviteqr/confirmed' , component: TicketConfirmedComponent},
	{ path: 'conviteqr/:code' , component: IdentificationComponent},
	{ path: 'puc80' , component: TicketsListComponent},
	{
		path: 'dashboard',
		component: DashboardLayoutComponent,
		children: [
			{ path: '', component: FinancialComponent, canActivate: [AuthGuard] },
			{ path: 'financial', component: FinancialComponent, canActivate: [AuthGuard] },
			{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
			{ path: 'documentos', component: DocumentosComponent, canActivate: [AuthGuard] },
			{ path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
			{ path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard] },
			{ path: 'events', component: EventsComponent },
			{ path: 'add-events', component: AddEventComponent },
			{ path: 'add-announcements', component: AddAnnouncementsComponent },
			{ path: 'view-announcements', component: ViewAnnouncementsComponent },
			{ path: 'add-poll', component: AddPollComponent },
			{ path: 'vote-poll' , component: VotePollComponent},
			{ path: 'store' , component: StoreComponent},
			{ path: 'products/:id' , component: ProductsViewComponent},
			{ path: 'cart' , component: CartComponent},
			{ path: 'completed-publish' , component: CompletedPublishComponent},
			{ path: 'sale-detail/:id' , component: SaleDetailComponent},
			{ path: 'coupons' , component: CouponsComponent},
			{ path: 'coupons-view/:id' , component: DetailsCouponsComponent},
			{ path: 'voucher' , component: VoucherComponent},
			{ path: 'card-subscriber' , component: CardSubscriberComponent},
			{ path: 'my-requests' , component: MyRequestsComponent},
			{ path: 'guests-purchases' , component: StoreGuestPurchasesComponent},
			{ path: 'guest-purchase-list' , component: StoreGuestPurchasesListComponent},
			{ path: 'guest-purchase-detail' , component: StoreGuestPurchaseDetailComponent},
			{ path: 'select-packge' , component: SelectPackgeComponent},
			{ path: 'register-user' , component: RegisterUserComponent},
		]
	},
	{
		path: 'convidado',
		component: DashboardLayoutComponent,
		children: [
			{ path: '', component: FinancialComponent, canActivate: [AuthGuard] },
			{ path: 'cadastro', component: FinancialComponent, canActivate: [] },
		]
	},
];

if(environment.CLIENT == Clients.YZ){
	routes = [
		{ path: 'payment/success' , component: PaymentSuccessComponent},
		{ path: 'payment/:hash' , component: PaymentLinkComponent},
		{ path: 'payment-digital/:hash' , component: PaymentLinkKanbanComponent},
		{ path: 'conviteqr/:code' , component: IdentificationComponent},
	]
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }