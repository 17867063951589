import { RestService } from './rest.service';
export class RelationsService extends RestService {
    private _url = 'Relationships'

    getRelations(filter_data: any, contains: string = '', order_string: string = '', page: number = 0, fields: string = '', limit: number = 5000, group = ''): any {
        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if (item.operator == null || item.operator == '') {
                qs += item.field + '|' + item.value + ';'
            } else {
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contains, fields: fields, group: group } });
    }
}