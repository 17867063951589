export class ResearcheQuestionAnswer {

  id:number;
  research_user_id:number;
  research_question_id:number;
  answer:string;  

  constructor(
    id:number = null,
    research_user_id:number = null,
    research_question_id:number = null,
    answer:string = null,
  ){
    this.id = id;
    this.research_user_id = research_user_id
    this.research_question_id = research_question_id
    this.answer = answer
  }

}