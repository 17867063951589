import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserGuestService } from '../services/user-guest.service';

@Component({
  selector: 'app-store-guest-purchases',
  templateUrl: './store-guest-purchases.component.html',
  styleUrls: ['./store-guest-purchases.component.scss']
})
export class StoreGuestPurchasesComponent implements OnInit {

  constructor(
    private userGuestService: UserGuestService,
    private router: Router,
  ) { }

  guests:any[] = []

  ngOnInit() {
    this.load()
  }

  async load() {
    const userId = JSON.parse(localStorage.getItem('auth')).user.id

    const filter = [
      {field: 'UsersGuests.users_id', value: userId}
    ]
    const getData: any = await this.userGuestService.getUsersGuests(filter, 'InvitedUser;StoresSalesPurchases;StoresSalesPurchases.StoresSalesStatus;StoresSalesPurchases.StoresSalesProducts.StoresProducts')

    if(getData.summary.total > 0){
      getData.items.map(item => {
        if(item.data.invited_user_id != null){
          this.guests.push({user_id: item.data.invited_user_id, name: item.data.InvitedUser.name, purchases: item.data.StoresSalesPurchases.length, data: item.data})
        }
      })
    }
  }

  showGuestList(guestData){
    localStorage.removeItem('guestList')
    localStorage.setItem('guestList', JSON.stringify(guestData))
    this.router.navigate(['/dashboard/guest-purchase-list'])
  }

}
