import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  constructor(
    private router: Router

  ) { }
  optionsMobile: any = [];
  pesquiseMobile: boolean = false;
  @Input() isComition: boolean = false;
  @Input() categories: any = 'events';
  @Input() cartVisible: boolean = false;
  @Input() model: string = '';
  @Input() load: any;
  actual_url: string = null;

  @Input() valueCart: number = 0;

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenu(this.router.routerState.snapshot.url);
      }
    });
    this.setActiveMenu(this.router.routerState.snapshot.url);
  }
  private setActiveMenu(url) {
    this.actual_url = url;
  }
  optioMobiles(i) {
    this.optionsMobile[i] = !this.optionsMobile[i]
  }

  openPesquise() {
    this.pesquiseMobile = !this.pesquiseMobile
  }

}
