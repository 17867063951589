import { Component, OnInit } from '@angular/core';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  categories          : any = 'events';    //events, announcements, polls
  subMenu             : any = 'subMenuShare';
  auth                : any;
  reads               : any = [];
  optionsMobile       : any = [];
  cardExt             : any = [];
  cardShare           : any = [];
  status              : any = [];
  show_name           : string = '';
  actual_img          : string = '';
  
  pesquiseMobile      : boolean = false;
  
  noEvents            : boolean = true;   // VARIAVEL QUE DEFINE QUANDO TEM E QUANDO NÃO TEM EVENTOS A SEREM EXIBIDOS
  
  noAnnouncements     : boolean = true;   // VARIAVEL QUE DEFINE QUANDO TEM E QUANDO NÃO TEM COMUNICADOS A SEREM EXIBIDOS
  
  polls               : boolean = true;  // VARIAVEL QUE DEFINE QUANDO TEM E QUANDO NÃO TEM ENQUETES A SEREM EXIBIDAS

  msgRead             : boolean = false;

  msg                 : boolean = false;

  possibles_status:any =  {
    confirmed: 1,
    // declined: 2
  }

  themes : any[] = [
    {
      imgs1: "https://media.istockphoto.com/vectors/seamless-geometrical-pattern-an-angular-ornament-vector-id1044361192",
      imgs2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4hGkXdpGhtLEHMqAvzEhYI4dewSJ7A6D4Sg&usqp=CAU",
      questions: "Qual tipo de cadeira devemos escolher para o baile?",
      vote: "2",
      closed : "22"
    },
    {
      imgs1: "https://media.istockphoto.com/vectors/seamless-geometrical-pattern-an-angular-ornament-vector-id1044361192",
      imgs2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4hGkXdpGhtLEHMqAvzEhYI4dewSJ7A6D4Sg&usqp=CAU",
      questions: "Qual tipo de musica devemos escolher para o baile?",
      vote: "",
      closed : "12"
    },
    {
      imgs1: "https://media.istockphoto.com/vectors/seamless-geometrical-pattern-an-angular-ornament-vector-id1044361192",
      imgs2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4hGkXdpGhtLEHMqAvzEhYI4dewSJ7A6D4Sg&usqp=CAU",
      questions: "Qual tipo de musica devemos escolher para o baile?",
      vote: "7",
      closed : "23"
    },
    {
      imgs1: "https://media.istockphoto.com/vectors/seamless-geometrical-pattern-an-angular-ornament-vector-id1044361192",
      imgs2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4hGkXdpGhtLEHMqAvzEhYI4dewSJ7A6D4Sg&usqp=CAU",
      questions: "Qual tipo de musica devemos escolher para o baile?",
      vote: "71",
      closed : "2"
    },
  ]

  atendences: any[] = [
    {
      priority: 1,
      name: "Medidas COVID-19",
      prev: "Mauris elementum rhoncus aliquam. Donec finibus, nisl vitae tincidunt tempor, magna massa tempor lorem, at iaculis sem arcu in elit. Quisque tincidunt elit sollicitudin, volutpat quam ut, consequat diam.",
      date: "24 MAR",
      status: null
    },
    {
      priority: 1,
      name: "Decoração Baile",
      prev: "Mauris elementum rhoncus aliquam. Donec finibus, nisl vitae tincidunt tempor, magna massa tempor lorem, at iaculis sem arcu in elit. Quisque tincidunt elit sollicitudin, volutpat quam ut, consequat diam.",
      date: "25 MAR",
      status: null

    },
    {
      priority: 2,
      name: "Decoração Baile",
      prev: "Mauris elementum rhoncus aliquam. Donec finibus, nisl vitae tincidunt tempor, magna massa tempor lorem, at iaculis sem arcu in elit. Quisque tincidunt elit sollicitudin, volutpat quam ut, consequat diam.",
      date: "25 MAR",
      status: null

    },
    {
      priority: 3,
      name: "Prazo Escolha Foto",
      prev: "Mauris elementum rhoncus aliquam. Donec finibus, nisl vitae tincidunt tempor, magna massa tempor lorem, at iaculis sem arcu in elit. Quisque tincidunt elit sollicitudin, volutpat quam ut, consequat diam.",
      date: "26 MAR",
      status: null

    }
  ]
    
  events:any[] = [
    {
      name: "Evento 1=da bebeideira",
      status: null,
      
    },
    {
      name: "Evento de missa da igreja do SENHOR",
      status: null,
     
    },
    {
      name: "Evento de missa da igreja do SENHOR do ceu",
      status: null,
      
    },
    {
      name: "Evento de missa da igreja do SENHOR das trevas",
      status: null,
      
    },
    {
      name: "Evento de missa da igreja do SENHOR mares infernais",
      status: null,
      
    },
  ]

  constructor(
    private authService: AuthMainService,
  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));

    
    this.auth.user.isComition = false; // QUANDO FOR COMISSÂO == TRUE; QUANDO FOR FORMANDO == false.  

		if ( this.auth.user.nickname != null ) {
			this.show_name	= this.auth.user.nickname;
		} else {
			let full_name	= this.auth.user.name.split(" ");
			this.show_name	= full_name[0];
		}
		if ( !localStorage.getItem('avatar') ) {
			this.authService.getAvatar(this.auth.user.id).then(result => {
				this.actual_img   = localStorage.getItem('avatar');
			});
		} else {
			this.actual_img   = localStorage.getItem('avatar');
		}
  }
  
  selectCategories(categories: string) {
    this.categories  = categories;
    return false;
  }

  openStatus(i) {
    this.status[i] = !this.status[i]
  }

  openExt(i) {
    this.cardExt[i] = !this.cardExt[i]
  }

  optioMobiles(i) {
    this.optionsMobile[i] = !this.optionsMobile[i]
  }

  openPesquise() {
    this.pesquiseMobile = !this.pesquiseMobile
  }

  openShare(i){
    this.cardShare[i] = !this.cardShare[i]
  }
  openReads(i) {
    this.reads[i] = !this.reads[i];
  }

  openUnread() {
    this.msg = !this.msg;
  }
  openUread() {
    this.msgRead = !this.msgRead
  }

  deleteAnnouncements(i){
    this.atendences.splice(i,1);
  }
  
  changeStatus(atendence, status: number){
    atendence.status = status
  }
  selectSub(subMenu: string) {
    this.subMenu  = subMenu;
    return false;
  }
}
