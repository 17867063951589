import { Research } from './research.model';


export class ResearchUser { 

  id:number;
  research_id:number;
  user_id:number;
  research_status_id:number
  hash:string
  research: Research

  constructor(
    id:number = null,
    research_id:number = null,
    user_id:number = null,
    research_status_id:number = null,
    hash:string = null,
    research: Research = new Research()
  ){
    this.id = id
    this.research_id = research_id
    this.user_id = user_id
    this.research_status_id = research_status_id
    this.hash = hash
    this.research = research
  }

}