import { HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceivablesLogService } from 'src/app/services/receivables_log.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { DomSanitizer } from '@angular/platform-browser';



enum STEPS {
  SHOW_BILL = 'SHOW_BILL',
  SEND_MAIL = 'SEND_MAIL'
}

@Component({
  selector: 'app-receivable-modal-bill',
  templateUrl: './receivable-modal-bill.component.html',
  styleUrls: ['./receivable-modal-bill.component.scss']
})
export class ReceivableModalBillComponent {

  constructor(
    public activeModal: NgbActiveModal,
    private receivablesLogsService: ReceivablesLogService,
    private general: GeneralComponent,
    private _sanitizer: DomSanitizer
  ) {
    // this.myAngularxQrCode = 'Your QR code data string';
    
  }

  step: STEPS = STEPS.SHOW_BILL

  @Input()
  receivables: any[] = []
  @Input()
  barcode: string
  @Input()
  receivableId: number
  @Input()
  dueDate: string
  @Input()
  totalValue: number = 0
  @Input()
  billLink: string
  @Input()
  qrCode: string = ''
  emailAddress: string
  qrCodeAsImage: any = ''

  showQrCode(){
    console.log('ONVERTEIMAGEQRCODE')
    this.qrCodeAsImage = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.qrCode);
    console.log(this.qrCodeAsImage)
  }

  sendMailStep() {
    this.step = STEPS.SEND_MAIL
  }

  async sendEmail() {
    const send: any = await this.receivablesLogsService.sendBillByMail(this.receivableId, this.emailAddress)

    if (send.summary.process) {
      this.activeModal.dismiss()
      return this.general.setMessage('E-mail enviado com sucesso!', 'success', 'Parabéns')
    }

    this.general.setMessage('Ocorreu um erro ao enviar o email.', 'error', 'Ops...')
    this.step = STEPS.SHOW_BILL
  }

  async downloadPdfFile() {
    window.open(this.billLink, "_blank", "toolbar=yes,scrollbars=no,resizable=no,top=50,left=50,width=800,height=600");
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.barcode).then().catch(e => console.error(e));
    this.general.setMessage('Copiador para área de transferência', 'success', 'Parabéns')
  }
}
