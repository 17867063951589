import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'add-announcements',
  templateUrl: './add-announcements.component.html',
  styleUrls: ['./add-announcements.component.scss']
})
export class AddAnnouncementsComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() {
    
  }

  peoplesSelected    : any[]=[
    {
      id: '1',
      name: 'Juninho Antonio Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null
    },
    {
      id: '2',
      name: 'Alana Silva',
      img_url: 'http://placehold.it/120x120&text=image1',
      status: null

    },
    {
      id: '3',
      name: 'Aurora Lamenha',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '4',
      name: 'Bruno Tavarez',
      img_url: null
    },
    {
      id: '5',
      name: 'Camila Mendes',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '6',
      name: 'Camila Oliveira',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '7',
      name: 'Flávia Shnowtz',
      img_url: null
    },
    {
      id: '8',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '9',
      name: 'Maria Luiza Silva',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '10',
      name: 'Rodrigo Soares',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
    {
      id: '11',
      name: 'Pedro Rafael',
      img_url: null
    },
    {
      id: '12',
      name: 'Toninho Lenzi',
      img_url: 'http://placehold.it/120x120&text=image1'
    },
  ]



}
