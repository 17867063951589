import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable()
export class StoresProductsCategoriesService extends RestService {
    private _url = 'StoresProductsCategories';
    private _urlProductsAndCategories = 'StoresProducts/getAvailableProducts';
    
    constructor(protected _http: HttpClient,
        protected loadingService: LoadingService) {
        super(_http, loadingService);
    }
    
    getStoresProductsCategories(filter_data: any, contain_string: string = '', order_string: string = `+${this._url}.id`, page: number = 0, fields: string = '', limit: number = 200, group: string = ''): any {
        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
            }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
    }
    
    getStoresProductsAndCategories(): any {
        return this.getApi(this._urlProductsAndCategories);
    }
}
