import { RestService } from './rest.service';
export class UsersRelationsService extends RestService {
	private _url = 'UsersRelations'

    async createUserRelation(item: any) {
        return await this.postApi(this._url, item)	
	}
	
    async updateUserRelation(id, data) {
        return await this.putApi(this._url + '/' + id, data);
    }

    async deleteUserRelation(id) {
        return await this.deleteApi(this._url + '/' + id)
    }

    async saveUserRelation(data) {
        return await this.postApi(this._url, data)
    }
}