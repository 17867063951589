export class Package{
    id: number;
    briefing_id: number;
    name: string;
    over: number;
    price: number;
    months_for_discharge: number;
    total_per_student: number;
    max_installments: number;
    discount_total_payment: number;
    readjustment_periodicity: number;
    readjustment_periodicity_alert: number;
    start_date: any;
    end_date: any;
    status: string;

    constructor(
        id: number = null,
        briefing_id: number = null,
        name: string = null,
        over: number = 0,
        price: number = null,
        months_for_discharge: number = 1,
        total_per_student: number = null,
        max_installments: number = 0,
        discount_total_payment: number = 0,
        readjustment_periodicity: number = 12,
        readjustment_periodicity_alert: number = 1,
        start_date: any = null,
        end_date: any = null,
        status: string = 'a'
    ){
        this.id = id;
        this.briefing_id = briefing_id;
        this.name = name;
        this.over = over;
        this.price = price;
        this.months_for_discharge = months_for_discharge;
        this.total_per_student = total_per_student;
        this.max_installments = max_installments;
        this.discount_total_payment = discount_total_payment;
        this.readjustment_periodicity = readjustment_periodicity;
        this.readjustment_periodicity_alert = readjustment_periodicity_alert;
        this.start_date = start_date;
        this.end_date = end_date;
        this.status = status;
    }

}