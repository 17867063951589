import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'layout-not-found',
  templateUrl: './layout-not-found.component.html',
  styleUrls: ['./layout-not-found.component.scss']
})
export class LayoutNotFoundComponent implements OnInit {
  @Input() isComition        : boolean = false;
  constructor() { }

  ngOnInit() {
    
  }

}

