export class StoreProductImage{
    id: number
    src: string

    constructor(
        id: number = null,
        src: string =null
    ){
        this.id = id
       this.src = src
    }
}