import { AddressesService } from './services/address.service';
import { Globals } from 'src/globals';
import { StatsService } from './services/stats.service';
import { PaymentsMethodsService } from './services/payments-methods.service';
import { UserGuestService } from './services/user-guest.service';
import { PartnersService } from './services/partners.service';
import { PartnersEstablishmentsUnitsService } from './services/partners-establishments-units.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { NewPasswordComponent } from './login/new-password.component';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout.component';
import { FinancialComponent } from './dashboard/financial.component';
import { ProfileComponent } from './dashboard/profile.component';
import { TogglePasswordDirective } from './directives/toggle-password.directive';
import { AuthMainService } from './services/auth.service';
import { UserService } from './services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { ContractQuotaService } from './services/contract-quota.service';
import { SocialService } from './services/social.service';
import { ImageService } from './services/image.service';
import { CurrencyFormat } from './pipes/currency-pipe';
import { ProfileDescComponent } from './elements/profile-desc/profile-desc.component';
import { OnboardComponent } from './onboard/onboard.component';
import { ProgressBarComponent } from './elements/progessbar/progess-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule } from 'angular2-toaster';
import { GeneralComponent } from './_utils/general/general.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { DocumentsComponent } from './documents/documents.component';
import { ResearchComponent } from './research/research.component';
import { DocumentosComponent } from './dashboard/documentos/documentos.component';
import { ContactComponent } from './dashboard/contact/contact.component';
import { AvatarComponent } from './_utils/avatar/avatar.component';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { LeftSideBarComponent } from './elements/left-side-bar/left-side-bar.component';
import { EventCardComponent } from './elements/event-card/event-card.component';
import { FilterComponent } from './elements/filter/filter.component';
import { DateDescriptionFormat } from './pipes/date.pipe';
import { EventsComponent } from './events/events.component';
import { PeopleAvatarComponent } from './elements/people-avatar/people-avatar.component';
import { AddEventComponent } from './add-event/add-event.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AddAnnouncementsComponent } from './add-announcements/add-announcements.component';
import { LayoutNotFoundComponent } from './elements/layout-not-found/layout-not-found.component';
import { LayoutNotFoundAnotherImageComponent } from './elements/layout-not-found-another-image/layout-not-found-another-image.component';
import { ViewAnnouncementsComponent } from './dashboard/schedule/view-announcements/view-announcements.component';
import { AddPollComponent } from './dashboard/schedule/add-poll/add-poll.component';
import { VotePollComponent } from './dashboard/schedule/vote-poll/vote-poll.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { StoresProductsCategoriesService } from './services/store_product_category.service';
import { StoresProductsService } from './services/stores_Products.service';
import { StoreComponent } from './store/store.component';
import { ProductsViewComponent } from './store-view/store-view.component';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { CartComponent } from './cart/cart.component';
import { CompletedPublishComponent } from './completed-publish/completed-publish.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { BreadcrumbsComponent } from './elements/breadcrumbs/breadcrumbs.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { CouponsComponent } from './coupons/coupons-list/coupons.component';
import { DetailsCouponsComponent } from './coupons/coupons-view/details-coupons.component';
import { VoucherComponent } from './coupons/voucher/voucher.component';
import { PartnersUsersVouchersService } from './services/partners-users-vouchers.service';
import { CardSubscriberComponent } from './coupons/card-subscriber/card-subscriber.component';
import { PartnersSubscriberService } from './services/partners-subscriber.service';
import { PartnersCategoriesService } from './services/partner-categories.service';
import { StoreSalesService } from './services/store-sales.service';
import { StoresSalesProductsService } from './services/store-salas-products.service';
import { StoresConfigsCollegesClassesService } from './services/stores-configs-colleges-classes.service';
import { VariablesService } from './services/variables.service';
import { CostsCentersService } from './services/costs-centers.service';
import { MyRequestsComponent } from './my-requests/my-requests/my-requests.component';
import { CarouselComponent } from './_utils/carousel/carousel.component';
import { GroupsUsersService } from './services/groups-users.service';
import { SaleDetailComponent } from './sale-detail/sale-detail.component';
import { MonthDescriptionFormat } from './_utils/pipes/date-description.pipe';
import { RelationsService } from './services/relations.service';
import { UsersRelationsService } from './services/users-relations.service';
import { SelectPackgeComponent } from './select-packge/select-packge.component';
import { ContractsService } from './services/contracts.service';
import { RegisterUserComponent } from './register-user/register-user.component';
import { LoadingComponent } from './_utils/loading/loading.component';
// import { LottieModule } from 'ngx-lottie';

import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './app-store/reducers/root.reducer';
import { LoadingService } from './services/loading.service';
import { MonthReceivableComponent } from './components/month-receivables/month-receivables.component';
import { PackagesBoughtsComponent } from './components/packages-boughts/packages-boughts.component';
import { ReceivablesCardListComponent } from './components/receivables-card-list/receivables-card-list.component';
import { ReceivableModalBillComponent } from './components/receivable-modal-bill/receivable-modal-bill.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NavbarComponent } from './components/store/navbar/navbar.component';
import { StoreGuestPurchasesComponent } from './store-guest-purchases/store-guest-purchases.component';
import { StoreGuestPurchasesListComponent } from './store-guest-purchases-list/store-guest-purchases-list.component';
import { StoreGuestPurchaseDetailComponent } from './store-guest-purchase-detail/store-guest-purchase-detail.component';
import { StoreGuestHeaderComponent } from './components/store/store-guest-header/store-guest-header.component';
import { NewAccountGuestComponent } from './new-account-guest/new-account-guest.component';
import { DashboardGuestLayoutComponent } from './dashboard/dashboard-guest.component';
import { LeftSideBarGuestComponent } from './elements/left-side-bar-guest/left-side-bar-guest.component';
import { PaymentLinkComponent } from './payment-link/payment/payment.component';
import { PaymentLinkKanbanComponent } from './payment-link/payment-link-kanban/payment.kanban.component';
import { PaymentSuccessComponent } from './payment-link/payment-success/payment-success.component';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { IdentificationComponent } from './tickets/identification/identification.component';
import { TicketsService } from './services/ticket.service';
import { TicketNotFoundComponent } from './tickets/ticket-not-found/ticket-not-found.component';
import { TicketConfirmedComponent } from './tickets/ticket-confirmed/ticket-confirmed.component';
import { TicketsListComponent } from './tickets/list/list.component';
import { ChecklistComponent } from './dashboard/checklist-important/checklist.component';
import { BloodTypeComponent } from './dashboard/blood-type/blood-type';


registerLocaleData(localePt);
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
]);

export function playerFactory() {
  return player;
}

@NgModule({
  entryComponents:[ReceivableModalBillComponent],
  declarations: [
    AppComponent,
    ReceivableModalBillComponent,
    CurrencyFormat,
    MonthDescriptionFormat,
    ChecklistComponent,
    BloodTypeComponent,
    CurrencyFormat,
    DateDescriptionFormat,
    DashboardLayoutComponent,
    DashboardGuestLayoutComponent,
    LeftSideBarGuestComponent,
    FinancialComponent,
    ForgotPasswordComponent,
    LoginComponent,
    NewPasswordComponent,
    OnboardComponent,
    ProfileComponent,
    ProfileDescComponent,
    ProgressBarComponent,
    TogglePasswordDirective,
    LoadingComponent,
    FooterComponent,
    DocumentsComponent,
    ResearchComponent,
    DocumentosComponent,
    ContactComponent,
    AvatarComponent,
    ScheduleComponent,
    LeftSideBarComponent,
    EventCardComponent,
    FilterComponent,
    EventsComponent,
    PeopleAvatarComponent,
    AddEventComponent,
    AddAnnouncementsComponent,
    LayoutNotFoundComponent,
    LayoutNotFoundAnotherImageComponent,
    ViewAnnouncementsComponent,
    AddPollComponent,
    VotePollComponent,
    StoreComponent,
    ProductsViewComponent,
    CartComponent,
    CompletedPublishComponent,
    BreadcrumbsComponent,
    NewAccountComponent,
    CouponsComponent,
    DetailsCouponsComponent,
    VoucherComponent,
    CardSubscriberComponent,
    MyRequestsComponent,
    CarouselComponent,
    SaleDetailComponent,
    SelectPackgeComponent,
    RegisterUserComponent,
    MonthReceivableComponent,
    PackagesBoughtsComponent,
    ReceivablesCardListComponent,
    NavbarComponent,
    StoreGuestPurchasesComponent,
    StoreGuestPurchasesListComponent,
    StoreGuestPurchaseDetailComponent,
    StoreGuestHeaderComponent,
    NewAccountGuestComponent,
    PaymentLinkComponent,
    PaymentLinkKanbanComponent,
    PaymentSuccessComponent,
    IdentificationComponent,
    TicketNotFoundComponent,
    TicketConfirmedComponent,
    TicketsListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TextMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToasterModule.forRoot(),
    IvyCarouselModule,
    FullCalendarModule,
    CrystalLightboxModule,
    CreditCardDirectivesModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthGuard,
    AuthMainService,
    LoadingService,
    ContractQuotaService,
    GeneralComponent,
    ImageService,
    ProgressBarComponent,
    StoresSalesProductsService,
    StoresConfigsCollegesClassesService,
    UserGuestService,
    UserService,
    SocialService,
    StoresProductsCategoriesService,
    StoresProductsService,
    PartnersEstablishmentsUnitsService,
    TicketsService,
    PartnersService,
    PartnersUsersVouchersService,
    PartnersSubscriberService,
    PaymentsMethodsService,
    PartnersCategoriesService,
    Globals,
    AddressesService,
    RelationsService,
    VariablesService,
    UsersRelationsService,
    GroupsUsersService,
    CostsCentersService,
    StoreSalesService,
    ContractsService,
    StatsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle(environment.CLIENT_LABEL);
  }
}