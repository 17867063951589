import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginBackgroundService {

	random() : string {
		const options: Array<number> = [...Array(4).keys()];
		return `bg-${options[Math.floor(Math.random() * options.length)]}`;
	}
}
