import { Component, Input, OnInit } from '@angular/core';
import { ReceivablesService } from 'src/app/services/receivables.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceivableModalBillComponent } from '../receivable-modal-bill/receivable-modal-bill.component';

@Component({
  selector: 'app-receivables-card-list',
  templateUrl: './receivables-card-list.component.html',
  styleUrls: ['./receivables-card-list.component.scss']
})
export class ReceivablesCardListComponent implements OnInit {

  constructor(
    private receivablesService: ReceivablesService,
    private general: GeneralComponent,
    private modalService: NgbModal,
  ) { }

  isCollapsed: boolean = true

  @Input()
  receivables: any[] = []
  originalReceivablesList: any[] = []

  @Input()
  years: any[] = ['Todos']
  selectMultiple: any[] = [];

  selected_year: string = 'Todos';
  selected_status: string = 'Todas';
  selected_status_id: number = null

  status: any = [
    { id: 2, name: 'Em Aberto' },
    { id: 3, name: 'Pago' },
    { id: 5, name: 'Atrasada' },
    { id: null, name: 'Todas' },
  ]

  sendMail: boolean = false;

  ngOnInit() {
    this.originalReceivablesList = [...this.receivables]

    this.receivables.map(rec => {
      const [y, ,] = rec.due_date.split('-')

      if (!this.years.includes(y)) {
        this.years.push(y)
      }

      if(rec.receivables_status_id == 5){
        this.selectMultiple.push(rec.id)
      }
      
    })
  }

  checkMultiple(recId: number, recStatus: number) {
    if(recStatus != 3){
      !this.selectMultiple.includes(recId) ? this.selectMultiple.push(recId) : this.selectMultiple.splice(this.selectMultiple.indexOf(recId), 1)
    }
  }

  async generatedmultipleBill(recId: number = null) {
    const recIds = (recId) ? [recId] : this.selectMultiple

    const selectedReceivables = this.receivables.filter(rec => this.selectMultiple.includes(rec.id))
    const bill: any = await this.receivablesService.generateBill(recIds)

    if (bill.summary.process && bill.summary.barcode != "" && bill.summary.link != null) {

      const jsonReturn = JSON.parse(bill.log.json)
      const modalRef = this.modalService.open(ReceivableModalBillComponent);

      modalRef.componentInstance.receivables = selectedReceivables
      modalRef.componentInstance.barcode = bill.summary.barcode
      modalRef.componentInstance.billLink = bill.summary.link
      modalRef.componentInstance.receivableId = bill.log.id
      modalRef.componentInstance.dueDate = bill.log.due_date
      modalRef.componentInstance.totalValue = jsonReturn.payment.amount_br
      modalRef.componentInstance.qrCode = bill.data.pix_info.encodedImage
      modalRef.componentInstance.showQrCode()

    } else {
      this.general.setMessage('Não foi possivel gerar um boleto, entre em contato com o responsável pela sua turma')
    }
  }


  filterStatus(index: number) {
    this.selected_status = this.status[index].name
    this.selected_status_id = this.status[index].id

    this.selected_status_id == null
      ?
      this.selected_year == 'Todos'
        ?
        this.receivables = this.originalReceivablesList
        :
        this.receivables = this.originalReceivablesList.filter(rec => rec.due_date.split('-')[0] === this.selected_year)

      :
      this.selected_year == 'Todos'
        ?
        this.receivables = this.originalReceivablesList.filter(rec => rec.receivables_status_id === this.selected_status_id)
        :
        this.receivables = this.originalReceivablesList.filter(rec => rec.receivables_status_id === this.selected_status_id && rec.due_date.split('-')[0] === this.selected_year)
  }

  filterYear(year: string) {
    this.selected_year = year

    this.selected_year == 'Todos'
      ?
      this.selected_status_id == null
        ?
        this.receivables = this.originalReceivablesList
        :
        this.receivables = this.originalReceivablesList.filter(rec => rec.receivables_status_id === this.selected_status_id)

      :
      this.selected_status_id == null
        ?
        this.receivables = this.originalReceivablesList.filter(rec => rec.due_date.split('-')[0] === this.selected_year)
        :
        this.receivables = this.originalReceivablesList.filter(rec => rec.due_date.split('-')[0] === this.selected_year && rec.receivables_status_id === this.selected_status_id)

  }

}
