import { ResearchQuestionOption } from './research_question_option.model';

export class ResearchQuestion {

  id:number;
  research_id:number;
  name:string;
  type:string;

  researchQuestionOptions: Array<ResearchQuestionOption>

  constructor(
    id:number = null,
    research_id:number = null,
    name:string = null,
    type:string = null,
    researchQuestionOptions: Array<ResearchQuestionOption> = []
  ){
    this.id = id
    this.research_id = research_id
    this.name = name
    this.type = type
    this.researchQuestionOptions = researchQuestionOptions
  }

}