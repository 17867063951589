import { Component, OnInit, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { Clients } from 'src/clients/clients.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

	@Input()
	type: string = 'entire';
	@Input()
	loading: boolean = false;
	@Input()
	modelRender: string;

	options: AnimationOptions | any = {
		path: '/assets/json/loadingformo.json',
		loop: true,
		autoplay: true,
	};

	animationCreated(animationItem: AnimationItem): void {}

	ngOnInit() {
		if(environment.CLIENT == Clients.YZ){
			this.options.path = '/assets/json/loadingyz.json';
		}
	}

}