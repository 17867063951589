import { Component, OnInit } from '@angular/core';
import { PartnersUsersVouchersService } from 'src/app/services/partners-users-vouchers.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {
  listVoucher: any[] = [];
  _userData: any

  constructor(
    private partnersUsersVouchersService: PartnersUsersVouchersService,
  ) { }

  ngOnInit() {
    const prefs = localStorage.getItem('auth')
    this._userData = JSON.parse(prefs)
    this.load()
  }

  async load() {
    const filter = [
      { field: 'PartnersUsersVouchers.users_id', value: this._userData.user.id },
    ];
    const couponsRequested: any = await this.partnersUsersVouchersService.getPartnerUsersVouchers(filter, 'PartnersEstablishments.PartnersCategories')
    couponsRequested.items.map(item => {
      this.listVoucher.push(item.data);
     
    })
    console.log(couponsRequested,'oie')
  }
}
