import { Component } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { Validation } from '../model/validation.model';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  providers: [ LoginBackgroundService ],
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

	background: string = 'bg-0';
	email: string = null;
	errorMessage: string = '';
	success: boolean = false;

	validation: Validation = new Validation();

	constructor(
		private loginBackgroundService: LoginBackgroundService,
		private userService: UserService,
		private router: Router
	) {}

	ngOnInit() {
		this.background = this.loginBackgroundService.random();
	}

	generate(){
		if(this.email != null){
			this.userService.newPassword(this.email).then((ret:any) => {
				if(!ret.summary.process){
					this.validation.fields.push('email_error');
					this.errorMessage	= 'E-mail não encontrado';
					this.success		= false;
				}else{
					this.success		= true;
					setTimeout(() => {
						this.router.navigate(['/login']);
					}, 3000);
				}
			})
		} else {
			this.validation.fields.push('email_error');
			this.errorMessage	= 'Digite um e-mail válido';
		}
	}
}
