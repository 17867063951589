import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/angular';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth'
  };
  
  
  constructor(public router: Router){   
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config', 'UA-158640426-1', 
                 {
                   'page_path': event.urlAfterRedirects
                 }
                );
        }
     }
  )}
}
