import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-packages-boughts',
  templateUrl: './packages-boughts.component.html',
  styleUrls: ['./packages-boughts.component.scss']
})
export class PackagesBoughtsComponent implements OnInit {

  constructor() { }

  @Input()
  projects: any = [];
  selectedProjectIndex = 0

  showProjList:boolean = false

  ngOnInit() {}

}
