import {Pipe} from '@angular/core';
import { Globals } from 'src/globals';

@Pipe({
    name: 'monthDescription'
})
export class MonthDescriptionFormat {

    constructor(
        private globals: Globals
    ){}
    
    transform(val: string): string {
        const dt = new Date(val)
        const mth = this.globals.getMonthLabel(dt.getMonth())
        if(window.matchMedia("(max-width: 767px)").matches){
            return mth.substring(0, 3)
        }
        return mth
    }
}