import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-new-account-guest',
  templateUrl: './new-account-guest.component.html',
  styleUrls: ['./new-account-guest.component.scss']
})
export class NewAccountGuestComponent implements OnInit {

  code: string = ''
  upload: boolean = true
  constructor(
    private modalService: NgbModal,
    private route: Router

  ) { }

  ngOnInit() {

  }

  navigateStore() {
    this.modalService.dismissAll()
    this.route.navigate(
      ['/dashboard/store']
    )
  }

  open(content) {
    this.modalService
      .open(content, {
        size: 'md',
        backdrop: 'static'
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );

  }

}
