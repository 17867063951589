import { Component, OnInit } from '@angular/core';
import { LoginBackgroundService } from 'src/app/services/login-background.service';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(
		private loginBackgroundService: LoginBackgroundService
  ) { }

  client = environment.CLIENT
	background: string = 'bg-0';

  ngOnInit() {
		this.background = this.loginBackgroundService.random();
  }

}
