
import { PartnersEstablishmentsUnitsService } from './../../services/partners-establishments-units.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Partner } from 'src/app/model/partner.model';
import { PartnersService } from 'src/app/services/partners.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-details-coupons',
  templateUrl: './details-coupons.component.html',
  styleUrls: ['./details-coupons.component.scss']
})
export class DetailsCouponsComponent implements OnInit {
  @ViewChild('modal', { static: true }) private popUpModal;
  partner: any = new Partner()
  loaded: boolean = false
  rolesView: boolean = false
  userData: any
  codeCoupon: string = ''
  constructor(
    private route: ActivatedRoute,
    private partnersEstablishmentsUnitsService: PartnersEstablishmentsUnitsService,
    private partnersService: PartnersService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.partner.id = <any>this.route.snapshot.paramMap.get('id');
    this.loadPartner()
    this._loadData()
  }

  _loadData() {
    const prefs = localStorage.getItem('auth')
    this.userData = JSON.parse(prefs)
  }

  async loadPartner() {
    const dist = localStorage.getItem("current_distance")
    this.partnersEstablishmentsUnitsService.getPartnersEstablishmentsUnits(
      this.partner.id,
      'PartnersEstablishments.PartnersCategories;Images;PartnersEstablishmentsUnitsOpeningHours;').then((ret: any) => {
        const data = ret.items.data
        this.partner = new Partner(
          data.PartnersEstablishments.id,
          data.name,
          data.PartnersEstablishments.roles,
          data.local_phone,
          data.whatsapp,
          data.PartnersEstablishments.description,
          data.PartnersEstablishments.discount_amount,
          +dist,
          data.Images,
          data.address,
          data.number,
          data.PartnersEstablishments.cover,
          data.neighborhood,
          data.site,
          data.instagram,
          data.PartnersEstablishments.discount_type,
        )
      })
    this.loaded = true
  }

  async requestCoupon() {
    if (this.partner.discount_type == 3) {
      const resquest: any = await this.partnersService.requestVoucher(this.partner.id, this.userData.user.id)
      this.codeCoupon =  resquest.summary.process.toString()
    } else {
      this.openModal(this.popUpModal)
    }
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openModal(content) {
    this.modalService.dismissAll()
    this.modalService
      .open(content, {
        ariaLabelledBy:
          'modal-basic-title',
      })
  }
}
