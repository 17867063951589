import { RestService } from './rest.service';
export class PaymentsMethodsService extends RestService {
    private _url = 'PaymentsMethods';
    
    async getPaymentsMethods(filter_data, contain_string = '', limit: number = 200, page: number = 0) {
        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if (item.operator == null || item.operator == '') {
                qs += item.field + '|' + item.value + ';'
            } else {
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, contains: contain_string } });
    }
}