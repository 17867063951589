import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../model/user.model';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {
  
	public maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
	public maskPhone = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

  acess: boolean = false
  nameController: string
  emailController: string
  cpfController: string
  phoneController: string
  newPassword: string
  repeatPassword: string
  actualPassword: string

  upload:any

  obscurePassword: boolean = true
  obscureConfirmPassword: boolean = true

  //TODO: VALIDAR VARIAVEIS ABAIXO
  fieldTextTypeActual:any
  updateButton:any
  toggleFieldTextTypeActual: any

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  async _doRegister() {
    var filter = { field: 'Users.email', value: this.emailController}

    const checkEmail = await this.userService.getUsers(filter)
  }

  changeAcess() {
    this.acess = !this.acess
  }

  checkPasswordMatch(){
    
  }

  savePassword
}
