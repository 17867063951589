import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceivablesService } from 'src/app/services/receivables.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { Globals } from 'src/globals';
import { ReceivableModalBillComponent } from '../receivable-modal-bill/receivable-modal-bill.component';

@Component({
  selector: 'app-month-receivables',
  templateUrl: './month-receivables.component.html',
  styleUrls: ['./month-receivables.component.scss']
})
export class MonthReceivableComponent implements OnInit {

  constructor(
    private receivableService: ReceivablesService,
    private modalService: NgbModal,
    private general: GeneralComponent,
    private globals: Globals
  ) { }

  @Input()
  installments: any[] = []

  totalValue: number = 0

  ngOnInit() {
    if(this.installments.length){
      this.installments.map(rec => this.totalValue += rec.due_value)
    }
  }

  async generateBill(){
    const recIds:any[] = this.installments.map(rec => rec.id)
    const bill: any = await this.receivableService.generateBill(recIds)
    
    if (bill.summary.process && bill.summary.barcode != "" && bill.summary.link != null) {

      const jsonReturn = JSON.parse(bill.log.json)
      const modalRef = this.modalService.open(ReceivableModalBillComponent);

      modalRef.componentInstance.receivables  = this.installments
      modalRef.componentInstance.barcode      = bill.summary.barcode
      modalRef.componentInstance.billLink     = bill.summary.link
      modalRef.componentInstance.receivableId = bill.log.id
      modalRef.componentInstance.dueDate      = bill.log.due_date
      modalRef.componentInstance.totalValue   = jsonReturn.payment.amount_br
      modalRef.componentInstance.qrCode       = bill.data.pix_info.encodedImage
      modalRef.componentInstance.showQrCode()

    } else {
      this.general.setMessage('Não foi possivel gerar um boleto, entre em contato com o responsável pela sua turma')
    }

  }

  get monthLabel(){
    const today = new Date()

    return this.globals.getMonthLabel(today.getMonth())

  }

}
