import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  items : any;
  constructor(
    private route : ActivatedRoute,
  ) { }

  ngOnInit() {
    this.setBreadCrumbs()
  }
  ngOnChanges() {
    this.setBreadCrumbs()
  }
  setBreadCrumbs() {
    this.items = this.route.snapshot.data['breadcrumbs'];
  }

  
}
