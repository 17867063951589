import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({ 
	selector: 'progess-bar',
	templateUrl: './progess-bar.component.html'
})
export class ProgressBarComponent implements OnInit{

	@Input()
	total_value: number = 0;
	@Input()
	total_waiting: number = 0;
	@Input()
	total_delayed: number = 0;
	@Input()
	total_paid: number = 0;

	@Input()
	percent_paid: any = 0;
	@Input()
	percent_delayed: any = 0;
	@Input()
	percent_waiting: any = 0;

	openTootipPaied:boolean = false
	openTootipWaiting:boolean = false
	openTootipDelayed:boolean = false
	
	// margin_delayed: number = 0
	// margin_delayed_text: number = 0
	// margin_paid: number = 0
	// margin_waiting: number = 0

	width_delayed: number = 0
	// width_delayed_text: number	= 0
	width_paid: number = 0
	// width_paid_text: number = 0
	width_waiting: number = 0

	min_width: number = 8;

	constructor(
		private router: Router
	) { }

	ngOnInit() {
        let checkPercent    = this.percent_waiting + this.percent_delayed + this.percent_paid;
        if ( checkPercent > 100) {
			let diff		= 100 - checkPercent;
			if ( this.percent_delayed > 10 )
				this.percent_delayed  = this.percent_delayed + diff;
			else if ( this.percent_waiting > 10 ) 
				this.percent_waiting  = this.percent_waiting + diff;
        }

		this.width_paid		= this.percent_paid;
		this.width_delayed	= this.percent_delayed;
		this.width_waiting	= this.percent_waiting;

		this.width_paid	= ( this.width_paid < this.min_width ) ? this.min_width : this.width_paid;
		this.width_delayed	= ( this.width_delayed < this.min_width ) ? this.min_width : this.width_delayed;
		this.width_waiting	= ( this.width_waiting < this.min_width ) ? this.min_width : this.width_waiting;
		
        let checkPercentText    = this.width_waiting + this.width_delayed + this.width_paid;
        if ( checkPercentText > 100) {
			let diff		= 100 - checkPercentText;
			if ( this.width_delayed > 10 )
				this.width_delayed  = this.width_delayed + diff;
			else if ( this.width_waiting > 10 ) 
				this.width_waiting  = this.width_waiting + diff;
        }
	}
	
}
