import { ReceivablesStatus } from './../model/receivables_status.model';
import { Receivable } from './../model/receivable.model';
import { Component, OnInit } from '@angular/core';
import { ReceivablesService } from '../services/receivables.service';
import { StoreSalesService } from '../services/store-sales.service';
import { GeneralComponent } from '../_utils/general/general.component';

@Component({
  selector: 'app-sale-detail',
  templateUrl: './sale-detail.component.html',
  styleUrls: ['./sale-detail.component.scss']
})
export class SaleDetailComponent implements OnInit {

  constructor(
    private storeSalesService: StoreSalesService,
    private receivablesService: ReceivablesService,
    private generalComponent: GeneralComponent,
  ) { }

  saleProduct: any = {
    created: new Date()
  }
  auth: any
  sale: any
  methodId: any
  receivableList: any = []
  listProducts: any = []
  selectMultiple: any = [];
  totalUnit: number = 0

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'))
    this.sale = JSON.parse(localStorage.getItem('sale'))
    this.loadSale(this.sale.id)
  }

  async loadSale(id) {
    const saleDatails: any = await this.storeSalesService.getStoreSale(id, 'Receivables.ReceivablesStatus;StoresSalesProducts.StoresProducts')

    this.saleProduct = saleDatails.items.data
    this.methodId = saleDatails.items.data.Receivables[0].payments_methods_id

    this.sale.Receivables.map(rec => {
      if(rec.ReceivablesStatus.id == 2) {
        rec.ReceivablesStatus.name = 'A VENCER'
      }
      if(rec.ReceivablesStatus.id == 3) {
        rec.ReceivablesStatus.name = 'PAGO'
      }
      if(rec.ReceivablesStatus.id == 5) {
        rec.ReceivablesStatus.name = 'ATRASADO'
      }
      
      const date = new Date(rec.due_date)
      const month = date.toLocaleString('default', { month: 'long' })

      

      rec.due_date = `${month}`
      rec.due_date = rec.due_date.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
      this.receivableList.push(rec)
    })

    saleDatails.items.data.StoresSalesProducts.map(item =>
      this.listProducts.push(item)
    )

    this.listProducts.map(item => {
      this.totalUnit += item.quantity
    })
    
  }

  checkMultiple(parcel_id) {
    if (this.selectMultiple.includes(parcel_id) - 1) {
      this.selectMultiple.push(parcel_id)
    } else {
      this.selectMultiple.splice(this.selectMultiple.indexOf(parcel_id), 1)
    }
  }

  async generatedmultipleBill() {
    console.log(this.selectMultiple)
    const bill: any = await this.receivablesService.generateBill(this.selectMultiple)
    if (bill.summary.process && bill.summary.barcode != "" && bill.summary.link != null) {
      if (bill.summary.process) {
        window.open(bill.summary.link, "_blank", "toolbar=yes,scrollbars=no,resizable=no,top=50,left=50,width=800,height=600");
      } else {

      }
    } else {
      this.generalComponent.setMessage('Não foi possivel gerar um boleto, verifique se ao menos uma parcela foi selecionada.')
    }
  }

}
