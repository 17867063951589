import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-guest-purchase-detail',
  templateUrl: './store-guest-purchase-detail.component.html',
  styleUrls: ['./store-guest-purchase-detail.component.scss']
})
export class StoreGuestPurchaseDetailComponent implements OnInit {

  constructor() { }

  guestName: string
  guestPurchaseItems: any[] = []
  created: string
  totalAmount: number
  purchaseId: string

  ngOnInit() {
    this.load()
  }

  async load() {
    const getGuestPurchase = JSON.parse(localStorage.getItem('guestPurchase'))

    this.guestName = getGuestPurchase.guestName
    this.guestPurchaseItems = getGuestPurchase.StoresSalesProducts
    this.totalAmount = getGuestPurchase.total
    this.purchaseId = getGuestPurchase.id
    this.created = getGuestPurchase.created
  }

}
