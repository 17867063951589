import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
@Injectable({
    providedIn: 'root'
})
export class PartnersService extends RestService {
    private _url = 'Partners'

    async subscribeToGazeta(user_id) { 
        let data = JSON.stringify({ user_id });
        return await this.postApi(this._url + '/subscribeToGazeta', data);
    }

    async requestVoucher(partnerEstablishmentsId, user_id) {
        let data = JSON.stringify({ partner_id: partnerEstablishmentsId, user_id });
        return await this.postApi(this._url + '/getVoucher', data);
    }

    async getPartner({ id, contains = '', order = '', page = 0, fields = '', limit = 5000, group = '' }) {
        return await this.getApi(this._url + '/', + { id, fields, contains });
    }
}
