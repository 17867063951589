import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { TicketsService } from 'src/app/services/ticket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private generalComponent: GeneralComponent,
    private ticketService: TicketsService,
    private router: Router
  ){}

  loading: boolean = false;
  clientCode: any = environment.CLIENT.toString()
  ticketForm: FormGroup;
  submitted: boolean = false;
  code: string
  className: string

  public phoneMask = ['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  ngOnInit(){
    document.getElementById('toast-container').style.width = '100vw';

    this.ticketForm = this._fb.group({
      name: [null, [Validators.required, Validators.minLength(3)]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.minLength(13)]],
    });

    this.load()
  }

  async load(){
    this.code = <any>this._route.snapshot.paramMap.get('code')

    const check = await this.ticketService.checkTicket(this.code)

    if(!check.summary.process){
      return this.router.navigate(['/conviteqr/notFound'])
    }

    this.className = check.summary.className
  }

  get f1() {
    return this.ticketForm;
  }

  async save() {

    this.submitted = true;
    if (this.ticketForm.status == "INVALID") {
      return this.generalComponent.setMessage("Por favor verifique todos os campos em vermelho");
    }

    const update = await this.ticketService.addTicketInfo(this.code, this.ticketForm.value.name, this.ticketForm.value.email, this.ticketForm.value.phone)
console.log(update.summary.process)
    if(update.summary.process){
      this.router.navigate(['/conviteqr/confirmed'])
    }else{
      this.generalComponent.setMessage(update.summary.message);
    }

  }

}
