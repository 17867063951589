import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";

@Injectable()
export class PartnersEstablishmentsUnitsService extends RestService {
    private _url = 'PartnersEstablishmentsUnits';

    async getPartnersEstablishmentsUnitsByDistanceAndCategories(data) {
        return await this.postApi(this._url + '/getUnits', data);
    }

    async getPartnersEstablishmentsUnits(id: number, contains_string: string='') {
        return this.getApi(this._url + '/' + id, { params: { contains:  contains_string} });
    }
}