import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class SocialService extends RestService {
  private _url = 'Socials';
  
  constructor(protected _http: HttpClient,
    protected loadingService: LoadingService) {
    super(_http, loadingService);
}

  insertSocial(social: any): any {
    let data = JSON.stringify(social);
    return this.postApi(this._url, data);
  }

  updateSocial(social: any): any {
    let data = JSON.stringify(social);
    return this.putApi(this._url + '/' + social.id, data);
  }

  deleteSocial(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getSocials(filter_data: any, contain_string: string = '', order_string: string = '-Socials.id', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Socials.id|' + filter_data.id + ';';
    }
    if (filter_data.reference && filter_data.reference != '') {
      qs += 'Socials.reference|' + filter_data.reference + ';';
    }
    if (filter_data.reference_id && filter_data.reference_id != '') {
      qs += 'Socials.reference_id|' + filter_data.reference_id + ';';
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }

}