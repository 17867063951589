import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent implements OnInit {
  showMenuMobile: boolean = false
  activeMenu: number = 1;

  constructor(
    private authService: AuthMainService,
    private router: Router
  ) { }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenu(this.router.routerState.snapshot.url);
      }
    });
    this.setActiveMenu(this.router.routerState.snapshot.url);
  }

  logout() {
    this.authService.unsetUser();
    this.router.navigate(['/login']);
  }

  private setActiveMenu(url: string) {
    const urlCheck = url.substring(1).split('/');
    url = `/${urlCheck[0]}/${urlCheck[1]}`;

    const financeMenuUrls = [
      '/dashboard/financial'
    ]

    const storeMenuUrls = [
      '/dashboard/store', '/dashboard/products', '/dashboard/cart',
      '/dashboard/completed-publish', '/dashboard/my-requests', '/dashboard/sale-detail', 
      '/dashboard/guests-purchases', '/dashboard/guest-purchase-list', '/dashboard/guest-purchase-detail'
    ]

    const profileMenuUrls = [
      '/dashboard/profile'
    ]

    if(financeMenuUrls.includes(url)) {
      this.activeMenu = 1;
    } else if(storeMenuUrls.includes(url)) {
      this.activeMenu = 2;
    } else if(profileMenuUrls.includes(url)) {
      this.activeMenu = 3;
    } else {
      this.activeMenu = 4;
    }
  }

  closeLeftMenu() {
    this.showMenuMobile = false
  }

}
