import { TestBed } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { AuthMainService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  tab                 : any = 'new';
  prev                : any = 'chats'; //chats  prevChat 
  optionsMobile       : any = [];
  statusH             : any = [];
  chat                : any = [];
  resp                : any = [];
  auth                : any;
  opt                 : any = [];
  pesquiseMobile      : boolean = false;
  comentdelet         : any = [];
  show_name           : string =  '';
  actual_img          : string = '';

  constructor(
    private authService: AuthMainService,
  ) { }

  ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
		if ( this.auth.user.nickname != null ) {
			this.show_name	= this.auth.user.nickname;
		} else {
			let full_name	= this.auth.user.name.split(" ");
			this.show_name	= full_name[0];
		}
		if ( !localStorage.getItem('avatar') ) {
			this.authService.getAvatar(this.auth.user.id).then(result => {
				this.actual_img   = localStorage.getItem('avatar');
			});
		} else {
			this.actual_img   = localStorage.getItem('avatar');
		}
  }
  deleteComent(i) {
    this.comentdelet[i] = !this.comentdelet[i]
  }

  openResp(i) {
    this.resp[i] = !this.resp[i]
  }
  optOpen(i) {
    this.opt[i] = !this.opt[i]
  }
  openChat(i) {
    this.chat[i] = !this.chat[i]
  }
  openStatus(i) {
    this.statusH[i] = !this.statusH[i]
  }
  optioMobiles(i) {
    this.optionsMobile[i] = !this.optionsMobile[i]
  }
  openPesquise() {
    this.pesquiseMobile = !this.pesquiseMobile
  }
  select(tab: string) {
    this.tab  = tab;
    return false;
  }

  selectChat(prev: string) {
    this.prev  = prev;
    return false;
  } 
}
