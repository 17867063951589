import { CAROUSEL_DATA_ITEMS } from './../constants/carousel.const';
import { IcarouselItem } from './../_utils/carousel/icarousel-item.metadata';
import { Component, OnInit } from '@angular/core';
import { StoreSalesService } from '../services/store-sales.service';
import { StoresProductsService } from '../services/stores_Products.service';
import { StoresProductsCategoriesService } from '../services/store_product_category.service';
import { UserGuestService } from '../services/user-guest.service';
import { StoresSalesProductsService } from '../services/store-salas-products.service';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})

export class StoreComponent implements OnInit {
  mouseDown = false;
  categories: any = []
  startX: any;
  scrollLeft: any;
  slider: any;
  cartList = []
  carouselData: IcarouselItem[ ] = CAROUSEL_DATA_ITEMS

  constructor(
    private storesProductsCategoriesService: StoresProductsCategoriesService
  ) { }

  ngOnInit() {
    this.loadCategories()
  }

  async loadCategories() {
    const getData = await this.storesProductsCategoriesService.getStoresProductsAndCategories()
    if (getData.summary.total > 0) {
      this.categories = getData.items
    }
    this.loadFromCart()
  }

  async loadFromCart() {
    this.cartList = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []

    if(this.categories.length && this.cartList.length){
      this.categories.map((cat) => {
        cat.products.map(prod => {
          const checkIfProdIsInCart = this.cartList.find(p => p.id == prod.id)
          if(checkIfProdIsInCart){
            prod.quantity = checkIfProdIsInCart.quantity
          }
        })
      })
    }
  }

  startDragging(e, flag, el) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }

  stopDragging(e, flag) {
    this.mouseDown = false;
  }

  moveEvent(e, el) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }

  changeQuantity(op, item) {
    if (op == '+') {
      if (item.availability > 0 && item.quantity >= item.availability) {
        item.quantity = item.availability
      } else {
        item.quantity += 1
      }
    } else {
      item.quantity -= 1
      if (item.quantity < 1) {
        item.quantity = 0
        this.cartList = this.cartList.filter((element) => element.id != item.id)
      }
    }

    if (item.quantity > 0) {
      if (!this.cartList.length) this.cartList.push(item)

      const verifyProductInCart = this.cartList.filter(prod => prod.id == item.id)

      if (verifyProductInCart.length == 0) {
        this.cartList.push(item)
      } else {
        const productInCart: any = this.cartList.find(prod => prod.id == item.id)
        productInCart.quantity = item.quantity
      }
    }

    localStorage.setItem('cart', JSON.stringify(this.cartList))
  }
}
