import { Component, Input, OnInit } from '@angular/core';
import { UserGuestService } from 'src/app/services/user-guest.service';
import { VariablesService } from 'src/app/services/variables.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';

@Component({
  selector: 'app-store-guest-header',
  templateUrl: './store-guest-header.component.html',
  styleUrls: ['./store-guest-header.component.scss']
})
export class StoreGuestHeaderComponent{

  constructor(
    private generalComponent: GeneralComponent,
    private userGuestService: UserGuestService,
    private variablesService: VariablesService
  ) { }

  @Input()
  invitationCode: string
  @Input()
  invitationShareMessage: string
  @Input()
  pageLinkApi: string

  ngOnInit() {
    this.load()
  }

  async load() {
    const userId = JSON.parse(localStorage.getItem('auth')).user.id

    const filter = [
      {field: 'UsersGuests.users_id', value: userId}
    ]
    const getData: any = await this.userGuestService.getUsersGuests(filter, '', '',0, '',1)

    if(getData.summary.total > 0){
      this.invitationCode = getData.items[0].data.invitation_code
    }

    this.loadVariables()
  }

  async loadVariables(){
    const filter = [
      {field: 'Variables.name', operator: 'IN', value: 'invitation_share_message,app_page_link'}
    ]
    const getVars:any = await this.variablesService.getVariables(filter)
    
    this.invitationShareMessage = getVars.items.find((va:any) => va.data.name == 'invitation_share_message').data.value.replace('{{codeReplace}}', this.invitationCode)
    this.pageLinkApi = getVars.items.find((va:any) => va.data.name == 'app_page_link').data.value
  }

  copyCode(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.invitationCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.generalComponent.setMessage('Copiado para área de transferência', 'success')
  }

}
