export class Address {
	id: number;
	type: string = 'none';
	address: string;
	country: string = 'Brasil';
	state: string = '';
	city: string;
	zip: string;
	neighborhood: string;
	number: string;
	complement: string;
	reference: string;
	reference_id: number;
	created: string;
	modified: string;
}