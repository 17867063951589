import { Action } from '@ngrx/store';

export enum LoadingActionTypes {
  SHOW = '[Loading] Show',
  CLOSE = '[Loading] Close',
}

export class LoadingShow implements Action {
  readonly type = LoadingActionTypes.SHOW;
}

export class LoadingClose implements Action {
  readonly type = LoadingActionTypes.CLOSE;
}

export type LoadingActions = LoadingShow | LoadingClose;
