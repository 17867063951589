import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})

export class RestService {
  
  protected serviceURL  = environment.SERVICE_API_URL;
  protected _tokenUrl   = environment.TOKEN_API_URL;
  // protected serviceURL = 'https://api.formaturas.app/';
  // protected _tokenUrl = 'https://api.formaturas.app/Portal/token?key=b2204a907fa9f08b5092e860211912d9'
  
  constructor(
    protected _http: HttpClient,
    protected loadingService: LoadingService
  ){
    localStorage.setItem('api_url', this.serviceURL);
  }

  async get(url: string, args = {}) {
    this.loadingService.show()
    const response = await this._http.get(url, args).toPromise()
    this.loadingService.close()

    return response
  }

  async getClientIp(){
    let urlFinal = this.serviceURL + '/clients/getClientIp';   
    
    this.loadingService.show()
    const response:any = await this._http.get(urlFinal).toPromise()
    this.loadingService.close()

    return response.ip
  }

  async getApi(url: string, args = {}, outer: boolean = false) {
    let urlFinal = this.serviceURL + url;   
    const accessToken = await this.getToken()

    if (args['params'] == undefined) {
      args['params'] = {};
    } 
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;
    if (outer)
      urlFinal = url;

    this.loadingService.show()
    const response: any = await this._http.get(urlFinal, args).toPromise()
    this.loadingService.close()

    if(response.summary && !response.summary.process && response.summary.errors && response.summary.errors.length > 0){
      const codesToLogout = [1002];
      let logout = false
      
      response.summary.errors.forEach(error => {
        if(codesToLogout.indexOf(error.code) > -1){
          logout = true;
        }
      });

      if(!logout){
        this.loadingService.close()
        return response;
      }else{
        localStorage.clear();
        window.location.reload();
      }

    }else{
      return response;
    }
  }

  async postApi(url: string, body: any, args = {}) {

    const accessToken = await this.getToken()

    if (args['params'] == undefined) {
      args['params'] = {};
    }
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;

    this.loadingService.show()
    const response = await this._http.post(this.serviceURL + url, body, args).toPromise()
    this.loadingService.close()

    return response
  }

  async patchApi(url: string, body: any, args = {}) {
    const accessToken = await this.getToken()
    if (args['params'] == undefined) {
      args['params'] = {};
    }
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;

    this.loadingService.show()
    const response = await this._http.patch(this.serviceURL + url, body, args).toPromise()
    this.loadingService.close()

    return response      
  }

  async putApi(url: string, body: any, args = {}) {
    const accessToken = await this.getToken()

    if (args['params'] == undefined) {
      args['params'] = {};
    }
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;

    this.loadingService.show()
    const response =  await this._http.put(this.serviceURL + url, body, args).toPromise()
    this.loadingService.close()
    
    return response
  }

  async deleteApi(url: string, args = {}) {
    const accessToken = await this.getToken()
    if (args['params'] == undefined) {
      args['params'] = {};
    }
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;
    this.loadingService.show()
    const response = await this._http.delete(this.serviceURL + url, args).toPromise()
    this.loadingService.close()

    return response
  }

  getToken() {
    let newToken: boolean = true;
    let storedToken: any = localStorage.getItem('token');
    if (storedToken && storedToken != 'undefined') {
      storedToken = JSON.parse(storedToken);
      let d = new Date();
      let month = d.getMonth() + 1;
      let monthStr = '';
      if (month < 10) {
        monthStr = '0' + month.toString();
      } else {
        monthStr = month.toString();
      }
      let day = d.getDate();
      let dayStr = '';
      if (day < 10) {
        dayStr = '0' + day.toString();
      } else {
        dayStr = day.toString();
      }
      let hour = d.getHours();
      let hourStr = '';
      if (hour < 10) {
        hourStr = '0' + hour.toString();
      } else {
        hourStr = hour.toString();
      }
      let minute = d.getMinutes();
      let minuteStr = '';
      if (minute < 10) {
        minuteStr = '0' + minute.toString();
      } else {
        minuteStr = minute.toString();
      }
      let second = d.getSeconds();
      let secondStr = '';
      if (second < 10) {
        secondStr = '0' + second.toString();
      } else {
        secondStr = second.toString();
      }
      let now = d.getFullYear() + "-" + monthStr + '-' + dayStr + "T" + hourStr + ":" + minuteStr + ":" + secondStr + '-02:00';
      if (storedToken.expires > now) {
        newToken = false;
        return Promise.resolve(storedToken.token);
      } else {
        newToken = true;
      }
    }
    if (newToken) {
      localStorage.removeItem('token');
      return this._http.get(this._tokenUrl).toPromise()
        .then((response: any) => {
          localStorage.setItem("token", JSON.stringify(response.data));
          return response.data.token;
        })
    }
  }

  getAuthorizationHeader(needAuthorization: boolean = false) {

    if (needAuthorization) {
      let tokenUser = localStorage.getItem('tokenUser');
      if (tokenUser) {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + tokenUser });
        return headers;
      }
    }
    let headers = new HttpHeaders();
    return headers;

  }

  protected toUpperCase(obj) {
    let no_change = ['country', 'reference', 'state'];
    for(var i in obj) {
      if ( no_change.indexOf(i) == -1 ) {
        let val =  obj[i];
        if(val != undefined || val != 'undefined'){
          if(typeof val == 'string')
            obj[i] = val.toUpperCase(); 
        }
      }
    }    
    return obj;
  }
}