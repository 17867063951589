import { RestService } from './rest.service';
export class UserGuestService extends RestService {
    private _url = 'UsersGuests';

    async getUsersGuests(filter_data: any, contain_string: string = '', order_string: string = `+${this._url}.id`, page: number = 0, fields: string = '', limit: number = 200, group: string = ''){
        
        filter_data.map(item => {
            if (item.field == 'Users.cpf') {
                item.value = item.value.replaceAll('.', '');
                item.value = item.value.replaceAll('-', '');
                item.value = item.value.replaceAll('/', '');
            }

            return item
        });

        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
            }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
    }
}   