import { GeneralComponent } from '../../_utils/general/general.component';
import { Component, OnInit, Output } from '@angular/core';
import { PartnersEstablishmentsUnitsService } from '../../services/partners-establishments-units.service';
import { Router } from '@angular/router';
import { PartnersCategoriesService } from 'src/app/services/partner-categories.service';
import { IcarouselItem } from 'src/app/_utils/carousel/icarousel-item.metadata';
import { CAROUSEL_DATA_ITEMS } from 'src/app/constants/carousel.const';
declare const navigator: any;

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
  categories: any[] = [];
  partnersUnits: any[] = [];
  categorieFilter: boolean = true;
  loaded: boolean = false;
  categoryName: string = 'Estabelecimentos';
  openSearch: boolean = false;

  defaultCategoryName: string = 'Estabelecimentos';
  searchUnits: string = '';
  public carouselData: IcarouselItem[] = CAROUSEL_DATA_ITEMS;

  // TODO: VALIDAR VARIAVEIS ABAIXO
  distanceFilter: any;
  openFilter: any;
  openOrder: any;

  constructor(
    private categoriesService: PartnersCategoriesService,
    private partnersEstablishmentsUnitsService: PartnersEstablishmentsUnitsService,
    private generalComponent: GeneralComponent,
    private router: Router
  ) {}

  ngOnInit() {
    this.load();
  }

  async load() {
    await this.loadCategories();
    await this.loadUnits();
  }

  async filterCategory(category) {
    this.categoryName = category.name;
    console.log(this.categoryName);
    await this.loadUnits(category.id);
  }

  async loadCategories() {
    const categories: any = await this.categoriesService.getPartnersCategories('CategoryImage');
    categories.items.map((item) => {
      if (item.data.CategoryImage) {
        item.data.CategoryImage.src = item.data.CategoryImage.src;
      }
      this.categories.push(item.data);
    });
    this.loaded = true;
  }

  viewPartener(part) {
    localStorage.setItem('current_distance', part.current_distance.toString());
    this.router.navigate(['/dashboard/coupons-view/' + part.id]);
  }

  async loadUnits(categoryId?) {
    try {
      let lat: number = 0;
      let long: number = 0;

      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

      if (permissionStatus.state === 'granted') {
        const position = await new Promise<any>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        lat = position.coords.latitude;
        long = position.coords.longitude;

        localStorage.setItem('lat', lat.toString());
        localStorage.setItem('long', long.toString());
      } else if (permissionStatus.state === 'denied') {
        this.generalComponent.setMessage(
          'Permissão para acessar a localização negada, você será redirecionado para a página inicial.'
        );
        setTimeout(() => {
          // this.router.navigate(['/dashboard'])
        }, 5000);
        return;
      } else if (permissionStatus.state === 'prompt') {
        const position = await new Promise<any>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        lat = position.coords.latitude;
        long = position.coords.longitude;

        localStorage.setItem('lat', lat.toString());
        localStorage.setItem('long', long.toString());
      } else {
        this.generalComponent.setMessage('Navegador sem suporte de localização.');
        setTimeout(() => {
          // this.router.navigate(['/dashboard'])
        }, 3000);
        return;
      }

      const filter = {
        latitude: lat,
        longitude: long,
        page: 0,
        max_distance: 10,
        order_by: 'distance',
        categories_ids: [],
        limit: 21,
        category_name: null,
      };

      if (this.searchUnits.length) filter.category_name = this.searchUnits;
      if (categoryId) filter.categories_ids = [categoryId];

      this.partnersUnits = [];
      const units: any = await this.partnersEstablishmentsUnitsService.getPartnersEstablishmentsUnitsByDistanceAndCategories(
        filter
      );

      if (units.summary.total > 0) {
        units.items.forEach((item: any) => {
          const unit = item;
          this.partnersUnits.push(unit);
        });
      }
    } catch (error) {
      console.error('Erro ao carregar as unidades:', error);
    }
  }


  
}
