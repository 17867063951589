import { RestService } from './rest.service';
export class PartnersSubscriberService extends RestService {
    private _url = 'SignaturesUsers';
    getPartnersSubscribers(filter_data, contains_string: string = '') {

        let qs: string = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }
        return this.getApi(this._url, { params: { query: qs, contains: contains_string } });
    }
}