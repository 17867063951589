import { StoreProductImage } from './image.model';
export class StoreProduct{
    id: number
    colleges_classes_id: number 
    stores_products_categories_id: number 
    finance_accounts_plans_id: number
    name: string
    description: string 
    price: number 
    discount: number 
    availability: number 
    actual_availability: number 
    limit_per_user: number 
    date_start: string 
    date_end: string
    status: string
    imageCover: StoreProductImage 
    storeProductImages: Array<StoreProductImage>

    constructor(
        id: number = null,
        colleges_classes_id: number = null, 
        stores_products_categories_id: number = null, 
        finance_accounts_plans_id: number = null,
        name: string = null,
        description: string = null, 
        price: number = null, 
        discount: number = null, 
        availability: number = null, 
        actual_availability: number = null, 
        limit_per_user: number = null, 
        date_start: string = null, 
        date_end: string = null,
        status: string = 'a',
        imageCover: StoreProductImage = new StoreProductImage(),
        storeProductImages: Array<StoreProductImage> = []
    ){
        this.id = id
        this.colleges_classes_id = colleges_classes_id
        this.stores_products_categories_id = stores_products_categories_id
        this.finance_accounts_plans_id = finance_accounts_plans_id
        this.name = name
        this.description = description
        this.price = price
        this.discount = discount
        this.availability = availability
        this.actual_availability = actual_availability
        this.limit_per_user = limit_per_user
        this.date_start = date_start
        this.date_end = date_end
        this.status = status
        this.imageCover = imageCover
        this.storeProductImages = storeProductImages
    }
}