import {Pipe} from '@angular/core';

@Pipe({
    name: 'dateDescription'
})
export class DateDescriptionFormat {

    constructor(
    ){}
    
    transform(value: Date, format = 'full') {
        const date  = new Date(value)
        const today = new Date()

        if(
            date.getDate() == today.getDate() &&
            date.getMonth() == date.getMonth() &&
            date.getFullYear() == date.getFullYear()
        ){
            return 'Hoje';    
        }

        let month = this.getMonthLabel(date.getMonth())
        if(format == "short"){
            month = month.substring(0, 3).toUpperCase()
        }

        return `${date.getDate().toString().padStart(2, '0')}/${month}/${date.getFullYear()}`
    }

    private getMonthLabel(month) {
        const months = {}
        months[0] = "Janeiro"
        months[1] = "Fevereiro"
        months[2] = "Março"
        months[3] = "Abril"
        months[4] = "Maio"
        months[5] = "Junho"
        months[6] = "Julho"
        months[7] = "Agosto"
        months[8] = "Setembro"
        months[9] = "Outubro"
        months[10] = "Novembro"
        months[11] = "Dezembro"

        return months[month]
    }
    
}