import { StoreProduct } from './../model/store_product.model';
import { StoresProductsService } from './../services/stores_Products.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreProductImage } from '../model/image.model';

@Component({
  selector: 'app-store-view',
  templateUrl: './store-view.component.html',
  styleUrls: ['./store-view.component.scss']
})
export class ProductsViewComponent implements OnInit {
  product: any = new StoreProduct()
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  slider: any
  cartList = []
  valueCartProd: number = 0;


  constructor(
    private route: ActivatedRoute,
    private productService: StoresProductsService,
    private router: Router
  
  ) { }

  ngOnInit() {
    this.product.id = <any>this.route.snapshot.paramMap.get('id');
    this.loadProduct()
  }

  loadProduct() {
    const apiUrl = localStorage.getItem('api_url')
    this.productService.getStoreProduct(this.product.id, "ImageCover;StoresProductsImages").then((ret: any) => {
      const imageCover = (ret.items.data.ImageCover && ret.items.data.ImageCover.src) ? `${apiUrl}${ret.items.data.ImageCover.src}` : ""
      console.log(ret)
      this.product = new StoreProduct(
        ret.items.data.id,
        ret.items.data.colleges_classes_id,
        ret.items.data.stores_products_categories_id,
        ret.items.data.finance_accounts_plans_id,
        ret.items.data.name,
        ret.items.data.description,
        ret.items.data.price,
        ret.items.data.discount,
        ret.items.data.availability,
        ret.items.data.actual_availability,
        ret.items.data.limit_per_user,
        ret.items.data.date_start,
        ret.items.data.date_end,
        ret.items.data.status,
        new StoreProductImage(null, imageCover),
        ret.items.data.StoresProductsImages.map(i => {
          return new StoreProductImage(i.id, apiUrl + i.src)
        })
      )
      this.product.quantity = 0
    })
  }

  changeQuantity(op, item) {
    if (op == '+') {
      if (item.availability > 0 && item.quantity >= item.availability) {
        item.quantity = item.availability
      } else {
        item.quantity += 1
      }
    } else {
      item.quantity -= 1
      
      if (item.quantity < 1) {
        item.quantity = 0
        this.cartList.filter((element) => {
          element.id == item.id
        })
      }
    }

    if (item.quantity > 0) {
      if (!this.cartList.length) this.cartList.push(item)
    }

    const verifyProductInCart = this.cartList.filter(prod => prod.id == item.id)

    if (verifyProductInCart.length == 0) {
      this.cartList.push(item)
    } else {
      const productInCart: any = this.cartList.find(prod => prod.id == item.id)
      productInCart.quantity = item.quantity
    }
    this.valueCartProd = this.cartList.length

  }
  addToCart() {
    localStorage.setItem('cart', JSON.stringify(this.cartList))
    this.router.navigate(['/dashboard/cart'])
  }

  startDragging(e, flag, el) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e, el) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }
}
