import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'people-avatar',
  templateUrl: './people-avatar.component.html',
  styleUrls: ['./people-avatar.component.scss']
})
export class PeopleAvatarComponent implements OnInit {

  constructor() { }
  @Input() userData :any ={};
  
  ngOnInit() {
    
  }
}
