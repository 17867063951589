import { LoadingActions, LoadingActionTypes } from '../actions/loading.actions';

export const initialState: boolean = false

export function loadingReducer(state = initialState, action: LoadingActions): boolean {

  switch (action.type) {
    case LoadingActionTypes.SHOW: 
      return state = !state;
    case LoadingActionTypes.CLOSE: 
      return state = !state;  
    default:
      return state;
  }
}
