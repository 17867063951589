import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TicketsService } from 'src/app/services/ticket.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class TicketsListComponent implements OnInit {

  constructor(
    private _fb: FormBuilder,
    private generalComponent: GeneralComponent,
    private ticketService: TicketsService,
  ) { }

  loading: boolean = false;
  ticketForm: FormGroup;
  submitted: boolean = false;
  tickets: any = []

  public docMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  ngOnInit(){
    document.getElementById('toast-container').style.width = '100vw';

    this.ticketForm = this._fb.group({
      cpf: [null, [Validators.required, Validators.minLength(11)]]
    });
  }

  get f1() {
    return this.ticketForm;
  }

  back() {
    this.tickets  = []
  }

  async search(){
    this.loading  = true
    const check = await this.ticketService.readByDocument(this.ticketForm.value.cpf)
    if ( check.items.length == 0 ) {
      this.generalComponent.setMessage('Nenhum convite encontrado com o CPF digitado.');
    } else {
      this.tickets  = check.items
      console.log(this.tickets)
    }
  }

}
