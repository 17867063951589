import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../model/user.model';
import { Address } from '../model/address.model';
import { Social } from '../model/social.model';
import { SocialService } from '../services/social.service';
import { GeneralComponent } from '../_utils/general/general.component';
import { AddressesService } from '../services/address.service';
import { RelationsService } from '../services/relations.service';
import { UsersRelationsService } from '../services/users-relations.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ChecklistComponent } from './checklist-important/checklist.component';
@Component({
	selector: 'profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent {

	constructor(
		private userService: UserService,
		private socialService: SocialService,
		private generalComponent: GeneralComponent,
		private addressesService: AddressesService,
		private relationsService: RelationsService,
		private usersRelationsService: UsersRelationsService,
	) { }

	residential: boolean = false
	acess: boolean = false
	upload: boolean = true
	family: boolean = false
	importantKnow: boolean = false
	auth: any;
	collegeClassName: string = null;
	user: User = new User();
	address: Address = new Address()
	facebook: Social = new Social()
	twitter: Social = new Social()
	instagram: Social = new Social()
	relation: any = {
		name: null,
		relation: null,
		phone: null,
		email: null
	}
	actualPassword: string
	newPassword: string
	repeatPassword: string
	avatarUrl: string = null;
	validateUser: boolean = false;
	validateAddress: boolean = false;
	validateAccess: boolean = false;
	validateRelation: boolean = false;
	saveImportantKnow: any;

	_relations: any = []
	_originalResponsiblesList: any[] = []
	_responsiblesList: any[] = []
	_responsiblesListErrors: any[] = []
	public maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
	public maskZip = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
	public maskPhone = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

	changedResponsibles: boolean = false

	ngOnInit() {
		this.auth = JSON.parse(localStorage.getItem('auth'));
		this.avatarUrl = localStorage.getItem('avatar')
		this.userService.getUser(this.auth.user.id, 'Addresses;UsersRelations.Users;UsersRelations.Relationships').then((result: any) => {
			this.user = result.items.data
			if (result.items.data.Addresses && result.items.data.Addresses.length > 0) {
				this.address = result.items.data.Addresses[0]
			}

			if (result.items.data.UsersRelationss && result.items.data.UsersRelationss.length > 0) {
				this.relation.name = result.items.data.UsersRelationss[0].Users.name
				this.relation.relation = result.items.data.UsersRelationss[0].Relationships.name
				this.relation.phone = result.items.data.UsersRelationss[0].Users.cellphone
				this.relation.email = result.items.data.UsersRelationss[0].Users.email
			}

			this.loadProject()
			this.loadSocials()
			this.loadResponsible()
			this.validate('user')
			this.validate('address')
		})
	}

    searchZip() {
        if ( this.address && this.address.zip && this.address.zip != '' )  {
            let zip = this.address.zip.replace(".","");
            zip = zip.replace("-","");
            zip = zip.replace(" ","");
            if(zip.length == 8){
                this.addressesService.getZip(zip).then((data:any) => {
                    if(data.summary.total > 0){
                        this.address.address = data.data.address;
                        this.address.city = data.data.city;
                        this.address.state = data.data.state;
                        this.address.neighborhood = data.data.neighborhood;
                    }
                });
            }
        }
    }

	async loadResponsible() {
		this._relations = []
		this._responsiblesList = []
		this._originalResponsiblesList = []

		const userData: any = await this.userService.getUser(this.auth.user.id, 'Cellphone;Address;UsersRelations.Relationships')
		const relations = await this.relationsService.getRelations([])

		if (relations.summary.total > 0) {
			relations.items.map((relation: any) => this._relations.push(relation.data))
		}

		if (userData.summary.total > 0) {
			userData.items.data.UsersRelationss.map((item: any) => {
				var relationShipController = 0

				relationShipController = item.Relationships.id
				this._responsiblesList.push(item)
				this._originalResponsiblesList.push({...item})
			})
		}
	}

	_addResponsible() {
		var nameController = ''
		var emailController = ''
		var phoneController = ''
		var relationshipIdController = null
		const newR = {
			name: nameController,
			email: emailController,
			phone: phoneController,
			nickname: nameController,
			related_id: 0,
			relationship_id: relationshipIdController,
			user_id: this.auth.user.id
		}
		this._responsiblesList.push(newR)
	}

	checkReponsibleChange() {
		var changed = false

		if (this._responsiblesList.length != this._originalResponsiblesList.length) {
			changed = true
		} else {
			this._responsiblesList.map((item: any, index: number) => {
				const keys = Object.keys(item)
				keys.map((key: any) => {
					if (item[key] != this._originalResponsiblesList[index][key]) {
						changed = true
					}
				})
			})
		}
		this.changedResponsibles = changed
	}

	validateAndSaveResponsible() {
		this._responsiblesListErrors = []

		this._responsiblesList.map((item: any, index: number) => {
			if(!this._responsiblesListErrors[index]){
				this._responsiblesListErrors[index] = []
			}

			if(!item.name) {
				this._responsiblesListErrors[index].push('name')
			}

			if(!item.email) {
				this._responsiblesListErrors[index].push('email')
			}else{
				const validateEmail = item.email.match(/([\w.%+-]+)@([\w-]+\.)+([\w]{2,})/i)
				if(!validateEmail){
					this._responsiblesListErrors[index].push('email')
				}
			}

			if(!item.phone) {
				this._responsiblesListErrors[index].push('phone')
			}else{
				const phone = item.phone.replace(/\D/g, '')
				if (phone.length != 11){
					this._responsiblesListErrors[index].push('phone')
				}
			}

			if(!item.relationship_id) {
				this._responsiblesListErrors[index].push('relationship_id')
			}
		})

		let hasErrors = false

		this._responsiblesListErrors.map((item: any) => {
			if(item.length > 0){
				hasErrors = true
			}
		})

		if(!hasErrors){
			this.saveResponsible()
			return
		}

		this.generalComponent.setMessage('Preencha os campos obrigatórios')
	}

	async saveResponsible() {
		await Promise.all(
			this._responsiblesList.map(async (item: any) => {
				item.nickname = /\s/g.test(item.name) ? item.name.split(' ')[0] : item.name
				item.phone = item.phone.replace(/\D/g, '')

				if (item.id) {
					await this.usersRelationsService.updateUserRelation(item.id, item)
				} else {
					await this.usersRelationsService.createUserRelation(item)
				}
			})
		)
		this.loadResponsible()
	}

	async remove(index) {
		if (!this._responsiblesList[index].id) {
			this._responsiblesList.splice(index, 1)
		} else {
			await this.usersRelationsService.deleteUserRelation(this._responsiblesList[index].id)
			this.loadResponsible()
		}
		this.checkReponsibleChange()
	}

	loadProject() {
		this.collegeClassName = JSON.parse(localStorage.getItem('auth')).user.is_in_college_class.collegeClassName
	}

	loadSocials() {
		const filter = { reference_key: 'users', reference_id: this.auth.user.id }
		this.socialService.getSocials(filter).then((result: any) => {
			if (result.summary.total > 0) {
				result.items.map(social => {
					if (social.data.social_media === 'facebook')
						this.facebook.reference = social.data.reference

					if (social.data.social_media === 'twitter')
						this.twitter.reference = social.data.reference

					if (social.data.social_media === 'instagram')
						this.instagram.reference = social.data.reference
				})
			}
		})
	}

	async _updateUser() {
		var _userData = JSON.parse(localStorage.getItem('auth'));

		_userData.user.name = this.user.name
		_userData.user.email = this.user.email
		_userData.user.cpf = this.user.cpf
		_userData.user.cellphone = this.user.cellphone

		delete _userData.user.created
		delete _userData.user.modified
		delete _userData.user.avatar
		delete _userData.user.tokenUser
		delete _userData.user.tokenUserExpires
		delete _userData.user.userId
		delete _userData.user.permissions
		delete _userData.user.activeSession
		delete _userData.user.sessionHash
		delete _userData.user.is_in_college_class

		const savedUser = await this.userService.updateUser(_userData.user)

		if (savedUser.summary.process) {
			this.generalComponent.setMessage('Seus dados foram atualizados', 'success')
		}
	}

	async _updateAddress() {
		var data = {
			id: this.address.id,
			reference: 'users',
			reference_id: this.auth.user.id,
			type: 'none',
			address: this.address.address,
			country: 'Brasil',
			state: this.address.state,
			city: this.address.city,
			zip: this.address.zip,
			neighborhood: this.address.neighborhood,
			number: this.address.number,
			complement: this.address.complement
		}
		var saveAddress

		if (this.address.id == null) {
			saveAddress = await this.addressesService._insertAddress(data)
		} else {
			saveAddress = await this.addressesService._updateAdress(data.id, data)
		}

		if (saveAddress.summary.process) {
			this.generalComponent.setMessage('Seu endereço foi atualizado', 'success')
		}
	}

	async checkActualPassword() {
		const check: any = await this.userService.login(this.auth.user.email, this.actualPassword)
		if (check.summary.process) {
			return true
		} else {
			return false
		}
	}

	validate(type: string = 'user') {
		let errors	= 0;
		switch (type) {
			case 'user':
				if ( this.user.name == '' )
					errors++;
				if ( this.user.email == '' )
					errors++;
				if ( this.user.cpf == '' )
					errors++;
				if ( this.user.cellphone == '' )
					errors++;
				if ( errors == 0 )
					this.validateUser	= true;
				break;
			case 'address':
				if ( this.address.zip == '' )
					errors++;
				if ( this.address.address == '' )
					errors++;
				if ( this.address.neighborhood == '' )
					errors++;
				if ( this.address.city == '' )
					errors++;
				if ( this.address.state == '' )
					errors++;
				if ( errors == 0 )
					this.validateAddress	= true;
				break;
			default:
				break;
		}
	}

	async savePassword() {
		if (await this.checkActualPassword()) {
			let savedPass = await this.userService.updateField(this.auth.user.id, 'password', this.newPassword)
			if (savedPass.summary.process) {
				this.generalComponent.setMessage('Sua senha foi alterada', 'success')
			}
		} else {
			this.generalComponent.setMessage('Senha atual incorreta.', 'error')
		}
	}

	checkPasswordMatch() {
		if (this.newPassword == this.repeatPassword) {
			this.validateAccess = true
		} else {
			this.validateAccess = false
		}

	}

	changeResidential() {
		this.residential = !this.residential
	}

	changeAcess() {
		this.acess = !this.acess
	}

	changeFamily() {
		this.family = !this.family
	}

	changeImportantKnow() {
		this.importantKnow = !this.importantKnow
	}

	messageWarning() {
		this.generalComponent.setMessage('Sua turma não pode ser alterada.', 'warning')
	}

	fieldTextTypeNew: boolean;
	fieldTextTypeActual: boolean;
	fieldTextTypeRepeat: boolean;

	toggleFieldTextTypeNew() {
		this.fieldTextTypeNew = !this.fieldTextTypeNew;
	}
	toggleFieldTextTypeActual() {
		this.fieldTextTypeActual = !this.fieldTextTypeActual;
	}
	toggleFieldTextTypeRepeat() {
		this.fieldTextTypeRepeat = !this.fieldTextTypeRepeat;
	}
}


