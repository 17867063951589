import { RestService } from './rest.service';
export class StatsService extends RestService {
    private _url = 'Stats';

    async preview(filter_data: any) {
        let qs: string = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }

        return this.getApi(this._url + '/preview/', { params: { query: qs } });
    }

    async situation(filter_data: any) {
        let qs: string = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }

        return this.getApi(this._url + '/situation/', { params: { query: qs } });
    }

    async delayers(filter_data: any) {
        let qs: string = '';

        if (Array.isArray(filter_data)) {
            filter_data.map(item => {
                if (item.operator == null || item.operator == '') {
                    qs += item.field + '|' + item.value + ';'
                } else {
                    qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }

        return this.getApi(this._url + '/delayers/', { params: { query: qs } });
    }
}