import { GeneralComponent } from './../general/general.component';
import { Component, Input, OnInit } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  imageAvatarurl: string = '' + ''
  hasImageCheck: boolean = false
  @Input() size: string = "normal";
  @Input() upload: boolean = false;
  loading: boolean = false
  auth: any

  constructor(
    private imageService: ImageService,
    private general: GeneralComponent
  ) { }
    
  ngOnInit() {
    
    this.auth = JSON.parse(localStorage.getItem('auth'))
    this.imageAvatarurl = localStorage.getItem('avatar')
    if (this.imageAvatarurl.split('/').length > 3) {
      this.hasImageCheck = true
    }
    
  }

  uploadAvatar(event) {
    this.loading = true;
    let file: File = event.target.files[0];
    this.imageService.insertImage('user', this.auth.user.id, file, '1').then(response => {
        if (response.summary.process == false || response.summary.message == 'error') {
          this.general.setMessage(response.summary.errorMessage);
        } else {
          this.loadImage();
          this.general.setMessage('Imagem alterada com sucesso', 'success');
        }
        this.loading = false;
      });
  }

  async loadImage(){
    const apiUrl = localStorage.getItem('api_url')
		const getAvatar = await this.imageService.getImages({ entity: 'user', entity_id: this.auth.user.id })
  
    if (getAvatar.items.length > 0 && getAvatar.items[getAvatar.items.length - 1].data.src != '') {
      const avUrl = `${apiUrl}${getAvatar.items[getAvatar.items.length - 1].data.src}`
      localStorage.setItem('avatar', avUrl);
      this.imageAvatarurl = avUrl
    }
  }
}
