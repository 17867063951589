import { IcarouselItem } from './../_utils/carousel/icarousel-item.metadata';

export const CAROUSEL_DATA_ITEMS: IcarouselItem[] = [
    {
        id: 0,
        title: {
            first: 'Lorem',
            second: 'ipsum'
        },
        subtitle: 'Descripcion 1',
        link: '/',

        image: 'assets/imgs/dashboard/png-teste.png'
    },
    {
        id: 1,
        title: {
            first: 'Lorem 3',
            second: 'ipsum'
        },
        subtitle: 'Descripcion 2',
        link: '/',

        image: 'assets/imgs/dashboard/png-teste2.png'
    },
    // {
    //     id: 2,
    //     title: {
    //         first: 'Lorem 2',
    //         second: 'ipsum'
    //     },
    //     subtitle: 'Descripcion 4',
    //     link: '/',

    //     image: 'assets/imgs/dashboard/png-teste2.png'
    // },
    // {
    //     id: 3,
    //     title: {
    //         first: 'Lorem 2',
    //         second: 'ipsum'
    //     },
    //     subtitle: 'Descripcion 5',
    //     link: '/',
    //     image: 'assets/imgs/dashboard/png-teste2.png'
    // }
]