import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-completed-publish',
  templateUrl: './completed-publish.component.html',
  styleUrls: ['./completed-publish.component.scss']
})
export class CompletedPublishComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
