import { Component, OnInit } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  background: string = 'bg-0';
  show: any = '/politica-de-privacidade';
  auth: any;

	constructor(
    private loginBackgroundService: LoginBackgroundService,
    private router: Router
	) { }

	ngOnInit() {
    this.auth = JSON.parse(localStorage.getItem('auth'));
    
    this.background = this.loginBackgroundService.random();
    this.show = this.router.url;
  }

}
