import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { BodyOutputType } from 'angular2-toaster';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class GeneralComponent implements OnInit {

    globalMessage: {
        error: [],
        success: []
    } = {
        error: [],
        success: []
    };
    errorMessage: any = [];
    successMessage: any = [];


    itemValue = new Subject();


    constructor(
        private toaster: ToasterService
    ) { }

    ngOnInit() {
    }

    getMessage(message = '', type = 'error', check = '', timeout: number) {
        if (type == 'error') {
            let config = {
                animation: 'fade',
                type: 'error',
                // title: check,
                body: message,
                bodyOutputType: BodyOutputType.TrustedHtml,
                timeout
            }
            this.toaster.pop(config);
        } else if (type == 'warning') {
            let config = {
                animation: 'fade',
                type: 'warning',
                // title: check,
                body: message,
                bodyOutputType: BodyOutputType.TrustedHtml,
                timeout
            }
            this.toaster.pop(config);
        } else {
            let config = {
                animation: 'fade',
                type: 'success',
                // title: check,
                body: message,
                bodyOutputType: BodyOutputType.TrustedHtml,
                timeout
            }
            this.toaster.pop(config);
        }
    }

    setMessage(message: any = '', type = 'error', check = 'Verifique os dados:', timeout = 5000) {
        return this.getMessage(message, type, check, timeout);
    }
}