import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from '../models/state.model';
import { loadingReducer } from './loading.reducer';

export const reducers: ActionReducerMap<AppState> = {
  "loading": loadingReducer
};


export const metaReducers: MetaReducer<AppState>[] = [];
