import { Component } from '@angular/core';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})

export class ChecklistComponent {

  importantSelector: boolean = false;
  inputDisabled: boolean = true;

  importantlist=[
    {id:1,name:'Sou pessoa celíaca',isselected:false, other:false},
    {id:2,name:'Sou intolerante à lactose',isselected:false, other:false},
    {id:3,name:'Tenho alergia à frutos do mar',isselected:false, other:false},
    {id:4,name:'Tenho alergia à oleaginosas',isselected:false, other:false},
    {id:5,name:'Sou pessoa vegana/vegetariana',isselected:false, other:false},
    {id:6,name:'Sou ou possuo familiar PcD',isselected:false, other:false},
    {id:7,name:'Outros',isselected:false, other:true},
  ];

  onChangeImportant($event) {
    const id = $event.target.value;
    const isChecked = $event.target.checked;

    this.importantlist = this.importantlist.map((d) => {
      if (d.id == id) {
        d.isselected = isChecked;
        if ( d.other )
          this.inputDisabled  = !this.inputDisabled
        return d;
      }
      return d;
    });
  }


}
