import { RestService } from './rest.service';
export class AddressesService extends RestService {
    private  _url: string = 'Addresses';
    private _getZip  = 'Addresses/brazilCep';

    async _updateAdress (id, data) {
        return await this.putApi(this._url + '/' + id, data);
    }

    async _insertAddress(data) {
        return await this.postApi(this._url, data);
    }

    public getZip(zip: string): any {
        return this.getApi(this._getZip + '/' + zip);
    }
}