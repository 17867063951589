import { Component } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validation } from '../model/validation.model';
import { UserService } from '../services/user.service';

@Component({
	selector: 'onboard',
	templateUrl: './onboard.component.html'
})
export class OnboardComponent {

	background: string = 'bg-0';
	key: any = '';
	email: any = '';
	
	validation: Validation = new Validation();

	constructor(
		private loginBackgroundService: LoginBackgroundService,
		private userService: UserService,
		private router: Router,
		private _route: ActivatedRoute
	) { }

	ngOnInit() {
        this.key	= <any>this._route.snapshot.paramMap.get('key');
		this.background = this.loginBackgroundService.random();

		this.userService.getUsers({key: this.key}).then(rs => {
			if ( rs.summary.total > 0 ) {
				this.email	= rs.items[0].data.email;
			} else {
				this.router.navigate(['/login']);
			}
		});
	}

}
