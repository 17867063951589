export class Class{
	id: number;
	colleges_id: number;
	colleges_campus_id: number;
	colleges_courses_id: number;
	name: string;
	// period: string = 'night';
	period: string;// = 'night';
	conclusion_date: any;
	graduation_date: any;
	size: number;
	status: string = 'a';
	

	_matchingData: any;

	constructor(
		id: number = null,
		colleges_id: number = null,
		colleges_campus_id: number = null,
		colleges_courses_id: number = null,
		name: string = null,
		// period: string = 'night',
		period: string = null,
		conclusion_date: any = null,
		graduation_date: any = null,
		size: number = null,
		status: string = 'a',
	){
		this.id = id;
		this.colleges_id = colleges_id;
		this.colleges_campus_id = colleges_campus_id;
		this.colleges_courses_id = colleges_courses_id;
		this.period = period;
		this.name = name;
		this.conclusion_date = conclusion_date;
		this.graduation_date = graduation_date;
		this.size = size;
		this.status = status;
	}
}