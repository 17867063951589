import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable()
export class StoresProductsService extends RestService {
    private _url = 'StoresProducts';

    constructor(protected _http: HttpClient,
        protected loadingService: LoadingService) {
        super(_http, loadingService);
    }

    getStoresProducts(filter_data: any, contain_string: string = '', order_string: string = `+${this._url}.id`): any {
        let qs: string = '';
        let paging: string = '';

        filter_data.map(item => {
            if (item.operator == null || item.operator == '') {
                qs += item.field + '|' + item.value + ';'
            } else {
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string} });
    }

    getStoreProduct(id, contain_string: string = '') {
        return this.getApi(this._url + '/' + id, { params: { contains: contain_string } });
    }

    updateStoreProduct(id, data) {
        return this.putApi(this._url + '/' + id, data);
    }

    
}
