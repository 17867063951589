import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable()
export class TicketsService extends RestService {
    private _url = 'Tickets';
    private _urlCheck = 'Tickets/checkInvitation';
    private _urlAddInfo = 'Tickets/updateTicketInvitation';

    constructor(protected _http: HttpClient,
        protected loadingService: LoadingService) {
        super(_http, loadingService);
    }

    addTicketInfo(ticketCode: string, name: string, email: number, phone: string) : any {
        const payload = {
            name, email, phone: phone.replace(/ /g, '')
        }

        return this.putApi(this._urlAddInfo + '/' + ticketCode, payload);
    }

    checkTicket(ticketCode: string) : any {
        return this.getApi(this._urlCheck, {params: { ticket_code: ticketCode}});
    }

    readByDocument(document: string) : any {
        return this.getApi(this._url, {params: { query: 'Users.cpf|' + document, contains: 'Users;BriefingsEvents', order: '+BriefingsEvents.event_datetime'}});
        // {{_url}}/Tickets?token=32fafd07f4a6baf892eceb0e1ea2b224&contains=Users&query=Users.cpf|04095060956
    }

}
