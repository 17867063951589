import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralComponent } from '../_utils/general/general.component';
import { ResearcheQuestionAnswer } from '../model/research_question_answer.model';
import { ResearchUser } from '../model/research_user.model';
import { Research } from '../model/research.model';
import { ResearchQuestion } from '../model/research_question.model';
import { ResearchQuestionOption } from '../model/research_question_option.model';
import { ResearchesUsersService } from '../services/researches_users.service';
import { ResearchesQuestionsAnswersService } from '../services/researches_questions_answers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private researchUserService: ResearchesUsersService,
    private researchQuestionsAnswers: ResearchesQuestionsAnswersService,
    private generalComponent: GeneralComponent
  ) { }

  researchUser  : ResearchUser = new ResearchUser()
  answers       : any[] = []
  emptyAnswers  : Array<any> =[]
  loading       : boolean = false;
  redirUrl      : string = 'https://formo.formaturas.app/';
  CLIENTE_LABEL : string = environment.CLIENT_LABEL;

  ngOnInit(){
    this.load()
  }

  load(){
    this.researchUser.hash = <any>this._route.snapshot.paramMap.get('hash')

    const filter = [
      {field: 'ResearchesUsers.hash', value: this.researchUser.hash}
    ]
    
    this.researchUserService.getResearchesUsers(filter, 'Researches.ResearchesQuestions.ResearchesQuestionsOptions').then((ret) => {
      if(ret.summary.total > 0){
        if(ret.items[0].data.research_status_id === 4){
          alert('Esta pesquisa ja foi finalizada, obrigado!');
          window.location.href = this.redirUrl
        }
        this.researchUser = new ResearchUser(
          ret.items[0].data.id, 
          ret.items[0].data.research_id, 
          ret.items[0].data.user_id, 
          ret.items[0].data.research_status_id, 
          this.researchUser.hash, 
          new Research(
            ret.items[0].data.Researches.id, 
            ret.items[0].data.Researches.name, 
            ret.items[0].data.Researches.status, 
            ret.items[0].data.Researches.ResearchesQuestions.map(item => 
              new ResearchQuestion(
                item.id, 
                item.research_id, 
                item.name, 
                item.type, 
                item.ResearchesQuestionsOptions.map(item => 
                  new ResearchQuestionOption(
                    item.id, 
                    item.research_question_id, 
                    item.value
                  )
                )
              )
            )
          )
        )

        if(this.researchUser.research.researchQuestions.length == 0){
          window.location.href = this.redirUrl
          return false;  
        }
        
        this.loadAnswers()

        if(this.researchUser.research_status_id === 1 || this.researchUser.research_status_id === 5){
          //ACESSOU A PESQUISA, ATUALIZA STATUS PARA ACESSADA CASO STATUS SEJA IGUAL A ENVIADO(CÓD 1)
          this.updateStatus(2)
        }
         
      }else{
        // REDIRECIONAR PAINEL DO FORMANDO
        window.location.href = this.redirUrl
      }
    }) 
  }


  async updateStatus(status_id) {
    await this.researchUserService.updateField(this.researchUser.id, 'research_status_id', status_id)
    this.researchUser.research_status_id = status_id
  }

  InitQuest(){
    this.updateStatus(3)
  }

  loadAnswers(){
    this.answers = JSON.parse(localStorage.getItem(`answer_${this.researchUser.id}`)) || []
  }

  saveAnswer(i, answer, type = 'text'){
    if(type == 'radio' || type == 'text')
      this.answers[i] = answer
    else if(type == 'checkbox'){
      
      if(typeof this.answers[i] == 'undefined'){
        this.answers[i] = []
      }

      if(this.answers[i].includes(answer)){
        this.answers[i] = this.answers[i].filter(item => item != answer)
      }else{        
        this.answers[i].push(answer)
      }      
    }
    localStorage.setItem(`answer_${this.researchUser.id}`, JSON.stringify(this.answers))
  }

  finishQuest(){
    this.loading = true

    const answersList: Array<ResearcheQuestionAnswer> = []
    this.researchUser.research.researchQuestions.map((question, index) => {
      let itemAnswer: ResearcheQuestionAnswer = new ResearcheQuestionAnswer();

      if(this.answers[index] && Array.isArray(this.answers[index])){
        itemAnswer = new ResearcheQuestionAnswer(null, this.researchUser.id, question.id, JSON.stringify(this.answers[index]))
      }else{
        itemAnswer = new ResearcheQuestionAnswer(null, this.researchUser.id, question.id, this.answers[index])
      }
      answersList.push(itemAnswer)
    })
    
    this.emptyAnswers.length = 0
    
    answersList.map((item, index) => {
      if(this.isJson(item.answer)){
        const parsedAnswer = JSON.parse(item.answer);
        if(Array.isArray(parsedAnswer) && parsedAnswer.length == 0){
          this.emptyAnswers.push(index)
        } 
      }
      if(item.answer == null || item.answer == '') this.emptyAnswers.push(index)
    })

    if(this.emptyAnswers.length == 0 ){
      //SALVAR RESPOSTAS E ATUALIZAR STATUS FORMULARIO

      this.researchQuestionsAnswers.insertMany(answersList).then(async () => {
        await this.updateStatus(4)
        localStorage.removeItem(`answer_${this.researchUser.id}`)        
      })


    }else{
      this.loading = false
      this.generalComponent.setMessage('Por favor, verifique os campos em vermelho')
    }

  }

  isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
  }

}
