import { ResearchQuestion } from './research_question.model';

export class Research {

  id:number;
  name:string;
  status:string;
  researchQuestions: Array<ResearchQuestion>


  constructor(
    id:number = null,
    name:string = null,
    status:string = 'a',
    researchQuestions: Array<ResearchQuestion> = []
  ){
    this.id = id
    this.name = name
    this.status = status
    this.researchQuestions = researchQuestions
  }

}