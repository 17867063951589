import { Component } from '@angular/core';
import { LoginBackgroundService } from '../services/login-background.service';

@Component({
  selector: 'new-password',
  templateUrl: './new-password.component.html',
  providers: [ LoginBackgroundService ]
})
export class NewPasswordComponent {

	background: string = 'bg-0';

	constructor(private loginBackgroundService: LoginBackgroundService) {}

	ngOnInit() {
		this.background = this.loginBackgroundService.random();
	}
}
