export class User {
	id: number;
	activeSession?: number;
	cpf: string;
	rg: string;
	rg_emitted_by: string;
	name: string;
	password?: string;
	login: string;
	email: string;
	birthdate: string;
	status: string = 'a';
	sessionHash: string;
	created: string;
	modified: string;
	created_by: number;
	modified_by: number;
	recovery_code: number;
	nickname: string;
	root: string; 
	tokenUser: string;
	_matchingData: any;
	cellphone: any;
	gender: any = 'male';
	group_id: any;
	language: string = 'pt';
}