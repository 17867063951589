import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginBackgroundService } from "src/app/services/login-background.service";
import { CreditCardValidators } from "angular-cc-library";
import { GeneralComponent } from "src/app/_utils/general/general.component";
import { StoreSalesService } from "src/app/services/store-sales.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractsService } from "src/app/services/contracts.service";
import { RestService } from "src/app/services/rest.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "src/app/app-store/models/state.model";
import { delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.kanban.html",
  styleUrls: ["./payment.kanban.component.scss"],
})
export class PaymentLinkKanbanComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private loginBackgroundService: LoginBackgroundService,
    private generalComponent: GeneralComponent,
    private storeSaleService: StoreSalesService,
    private general: GeneralComponent,

    private contractsService: ContractsService,
    private route: ActivatedRoute,
    private restService: RestService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  public creditCardMask = [ /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/ ];
  public cardDueDateMask = [/\d/, /\d/, "/", /\d/, /\d/];
  public cardCvvMask = [/\d/, /\d/, /\d/];

  newCreditCardForm: FormGroup;
  creditCardFormSubmitted: boolean = false;

  background: string = "bg-0";
  activeBtn: boolean = false;
  saleItems: any[] = [];
  showErrorMessage = false;
  errorMessage: string;
  saleHash: string;
  sugestedInstallmentsNumber: number;
  maxInstallmentsNumber: Array<number> = [];
  saleTotal: number = 0;
  loading$: Observable<boolean>;
  buyerName: string = "";
  clientCode: any = environment.CLIENT.toString();
  verifyContractCredCardBills: boolean = false;
  verifyStartContractCard:boolean = false;
  verifyContractCredCard :boolean = false
  sale:any;
  loading: boolean = true;
  saleId:any;


  contractUrl: string = ''; 
  contractDownloadSigned: string = '';
  contractStatus: string = '';
  contractStatusMethod: any ;




  ngOnInit() {
    this.loading$ = this.store.select("loading").pipe(delay(0));
    document.getElementById("toast-container").style.width = "100vw";
    this.background = this.loginBackgroundService.random();
    this.saleHash = <any>this.route.snapshot.paramMap.get("hash");
    this.maxInstallmentsNumber = Array.from(Array(12).keys()).map((i) => i + 1);

    this.newCreditCardForm = this._fb.group({
      creditCardName: [null, [Validators.required, Validators.minLength(3)]],
      creditCard: [null, [Validators.required, Validators.minLength(17), Validators.maxLength(19)]],
      // creditCard: [null, [CreditCardValidators.validateCCNumber]],
      expirationDate: [null, [CreditCardValidators.validateExpDate]],
      cvc: [
        null,
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
      qtyInstallments: ["", [Validators.required, Validators.min(1)]],
      checkBoxControl: [false, [Validators.requiredTrue]],
    });

    this.loadSale();
  }


  async openContractSigned(){
    if(!this.contractDownloadSigned){
      window.location.reload()
      const errorMessage = 'Seu contrato leva alguns segundos até ficar pronto'
      this.general.setMessage(errorMessage);
    }else{
      window.open(this.contractDownloadSigned);

    }
  }
  
 
  async changeVerifyContractClickSing() {
    const getSale: any = await this.storeSaleService.getStoreSaleByHash(this.saleHash);
    const contractUrl = getSale.items.Document.url
    if (getSale.items.Document.url) {
      window.open(contractUrl);
    }



}

  async verifyContractIsSign(){
        const getSale: any = await this.storeSaleService.getStoreSaleByHash(this.saleHash);


        this.contractStatus = getSale.items.Document.status;
        this.contractStatusMethod = getSale.items.Receivables[0].payments_methods_id;


      if (this.contractStatus === 'closed' && this.contractStatusMethod === 7) {
        this.loading = true

        this.verifyContractCredCardBills = true;
        this.verifyStartContractCard = false;
        this.verifyContractCredCard =false
      }
      if (this.contractStatusMethod == 8 && this.contractStatus === 'closed') {
        this.loading = true

        this.verifyContractCredCardBills = false;
        this.verifyStartContractCard = false;
        this.verifyContractCredCard =true
  
      } 
      if(this.contractStatus === 'running' || this.contractStatus === 'created'){
        this.loading = true

        this.verifyStartContractCard = true;
        this.verifyContractCredCardBills = false;
        this.verifyContractCredCard =false
  
      }
    }
  


    async generateAllBills(saleId: any) {
      try {
            this.loading = true
        const generateAll: any = await this.storeSaleService.generateAllBills(saleId);
        this.loading = false;
        if (generateAll.summary.process == true) {
          const val = generateAll.items.link;
          navigator.clipboard.writeText(val);
          window.open(val)
          this.general.setMessage('Boleto gerado com sucesso !');
        } else {
          if (generateAll.summary.message == 'missing_data') {
            let message = '';
            generateAll.summary.errors.map((err) => {
              message += err.description + ' ';
            });
            this.general.setMessage(message);
          } else {
            generateAll.summary.errors.map((data: any) => {
              if (data.code != '0002') {
                this.general.setMessage(data.description);
              }
            });
          }
        }
      } catch (error) {
        this.general.setMessage('Erro ao gerar faturas. Por favor, tente novamente mais tarde.');
      }
    }
    

  async downloadContracrSigned(){
        this.openContractSigned();
    
  }
  
  async loadSale() {
    const getSale: any = await this.storeSaleService.getStoreSaleByHash(
      this.saleHash,
    );
    this.contractStatusMethod = getSale.items.Receivables[0].payments_methods_id;
    this.contractStatus = getSale.items.Document.status;
    this.contractDownloadSigned = getSale.items.Document.url_signed

  
    this.verifyStartContractCard = true;
    this.verifyContractCredCardBills = false;
    this.verifyContractCredCard =false
  
    if (this.contractStatus === 'closed' && this.contractStatusMethod === 7) {
      this.verifyContractCredCardBills = true;
      this.verifyStartContractCard = false;
      this.verifyContractCredCard =false
    }
    if (this.contractStatusMethod == 8 && this.contractStatus === 'closed') {
      this.verifyContractCredCardBills = false;
      this.verifyStartContractCard = false;
      this.verifyContractCredCard =true

    } 
    if(this.contractStatus === 'running' || this.contractStatus === 'created'){
      this.verifyStartContractCard = true;
      this.verifyContractCredCardBills = false;
      this.verifyContractCredCard =false

    }
  
  
    if (!getSale.summary.process) {
      const errCode = getSale.summary.errors[0].code;
  
      if (errCode == 2002) {
        alert("Link de pagamento não encontrado!");
        this.router.navigate(["/"]);
      }
    }
    this.sugestedInstallmentsNumber = getSale.items.Receivables.length;
    this.saleTotal = getSale.items.total;
  
    if (this.sugestedInstallmentsNumber) {
      if (this.sugestedInstallmentsNumber > 12) {
        this.sugestedInstallmentsNumber = 12;
      }
  
      this.newCreditCardForm.controls["qtyInstallments"].setValue(
        this.sugestedInstallmentsNumber
      );
    } else {
      this.newCreditCardForm.controls["qtyInstallments"].setValue(1);
    }
  
    this.saleItems = getSale.items.StoresSalesProducts;
    this.buyerName = getSale.items.Users.name;
    this.saleId = getSale.items.id
  }
  

  get f1() {
    return this.newCreditCardForm;
  }

  async execPurchase() {
    this.creditCardFormSubmitted = true;
    if (this.newCreditCardForm.status == "INVALID") {
      return this.generalComponent.setMessage(
        "Por favor verifique todos os campos em vermelho"
      );
    }
    const ip = await this.restService.getClientIp();

    const data = {
      hash: this.saleHash,
      installments: +this.newCreditCardForm.value.qtyInstallments,
      card_number: this.newCreditCardForm.value.creditCard,
      card_holder_name: this.newCreditCardForm.value.creditCardName,
      card_expiration: this.newCreditCardForm.value.expirationDate,
      card_security: this.newCreditCardForm.value.cvc,
      mode: "web",
      remote_ip: ip,
    };

    const execPay: any = await this.storeSaleService.execStoreSalePayment(data);

    if (execPay.summary.process) {
      this.router.navigate(["/payment/success"]);
      return;
    }
    // 5184019740373151 -> CARTAO PARA NEGAR COMPRA EM HMLG
    // 4111111111111111 -> CARTAO PARA APROVAR COMPRA EM HMLG

    let errMessage = "";
    if (execPay.summary.message == "missing_data") {
      execPay.summary.errors.map((err) => {
        errMessage += err.description + ", ";
      });
      this.generalComponent.setMessage(errMessage.slice(0, -2));
    } else {
      execPay.summary.errors.errors.map((err) => {
        errMessage += err.description + ", ";
      });
      this.errorMessage = errMessage.slice(0, -2);
      this.showErrorMessage = true;
    }
  }

  getCardType() {
    const number = this.newCreditCardForm.value.creditCard.replace(/\s/g, "");
    let brand = "unknown";

    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        brand = "Visa";

    // Mastercard
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
     brand = "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        brand = "Amex";


    if(brand == 'Amex'){
      this.creditCardMask = [ /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/ ];
      this.cardCvvMask = [/\d/, /\d/, /\d/, /\d/];
      return
    }

    this.creditCardMask = [ /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/ ];
    this.cardCvvMask = [/\d/, /\d/, /\d/];
  }
}
