import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormTeste } from '../model/form-teste.model';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  
})

export class AddEventComponent implements OnInit {
  submitted             : boolean = false;
  hoursOpen             : boolean = false;
  minutesOpen           : boolean = false;

  selectedHour          : any = null
  selectedMinute        : any = null

  form                  : any;
  minutes               : any[] = []
  hours                 : any[] = []  

  public mask =[ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      titulo: [null, [Validators.required]],
      local: [null, [Validators.required]],
      endereco: [null, [Validators.required]],
      data: [null, [Validators.required]],
      hora: [null, [Validators.required]],
      minuto: [null, [Validators.required]],
      venda: [null],
      descricao: [null],
    });
    this.loadTEmp()
    this.loadTimes()
  }

  loadTimes() {
    const hoursTotal = 24
    const minutesTotal = 60

    for(let i=0; i<hoursTotal; i++){
      this.hours.push(i.toString().padStart(2, '0'))
    }
    for(let i=1; i<minutesTotal; i++){
      this.minutes.push(i.toString().padStart(2, '0'))
    }
  }

  loadTEmp() {
    const erifyTemp = localStorage.getItem('neweventform')
    if(erifyTemp != null){
      this.form.patchValue(JSON.parse(erifyTemp))
      this.selectedHour = JSON.parse(erifyTemp).hora
      this.selectedMinute = JSON.parse(erifyTemp).minuto
    }
  }

  save() {
    this.submitted = !this.submitted;
    if (this.form.status == "VALID") {
      const prasalver = new FormTeste(
        this.form.value.titulo,
        this.form.value.local,
        this.form.value.endereco,
        this.form.value.data,
        this.form.value.horario,
        this.form.value.venda,
        this.form.value.descricao
      )
    }
  }

  saveTEmp(){
    localStorage.setItem('neweventform', JSON.stringify(this.form.value) )
  }

  openHours() {
    this.hoursOpen = !this.hoursOpen
    this.minutesOpen = false
  }

  openMinutes() {
    this.minutesOpen = !this.minutesOpen
    this.hoursOpen = false
  }

  get f1() {
    return this.form.controls;
  }

  selectHour (value) {
    this.selectedHour = value
    this.form.patchValue(
      {
        hora: value
      }
    )
  }
  
  selectMinute (value) {
    this.selectedMinute = value
    this.form.patchValue(
      {
        minuto: value
      }
    )
  }
}
