import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ReceivablesService extends RestService {
  private _url = 'Receivables';
  
  constructor(protected _http: HttpClient,
    protected loadingService: LoadingService) {
    super(_http, loadingService);
}

  insertReceivable(Receivable: any): any {
    let data = JSON.stringify(Receivable); 
    return this.postApi(this._url, data);
  }

  updateReceivable(Receivable: any): any {
    let data = JSON.stringify(Receivable);
    return this.putApi(this._url + '/' + Receivable.id, data);
  }

  deleteReceivable(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getReceivables(filter_data: any, contain_string: string = '', order_string: string = '+Receivables.id', page: number = 0, fields: string = '', limit: number = 20, group: string = ''): any {
    let qs: string = '';
    let paging: string = '';

    if (Array.isArray(filter_data)) {
      filter_data.map(item => {
          if (item.operator == null || item.operator == '') {
              qs += item.field + '|' + item.value + ';'
          } else {
              qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
  }
  
  getReceivable(id, contain_string: string = ''){
    return this.getApi(this._url + '/' + id, { params: { contains: contain_string }});
  }

  generateBill(receivablesIds: Array<number>){
    let data = JSON.stringify({'receivable_id': JSON.stringify(receivablesIds), 'mode': 'web'});
    
    return this.postApi(this._url + '/generateBill', data);
  }
  
  createReceivable(recData) {
    let data = JSON.stringify(recData);
    return this.postApi(this._url, data);
  }
}