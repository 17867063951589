export class ResearchQuestionOption {

  id:number;
  research_question_id:number;
  value:string;

  constructor(
    id:number = null,
    research_question_id:number = null,
    value:string = null,
  ){
    this.id = id
    this.research_question_id = research_question_id
    this.value = value
  }
}