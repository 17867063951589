import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[toggle-password]'
})
export class TogglePasswordDirective {
  private _shown = false;
  private _defaultClasses = 'form-icon icon icon-show-pw';

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;

    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.setAttribute('class', this._defaultClasses);
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.setAttribute('class', this._defaultClasses);
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');

    span.setAttribute('class', this._defaultClasses);
    span.addEventListener('click', (event) => { this.toggle(span); });
    parent.appendChild(span);
  }
}